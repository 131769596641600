import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes} from "react-icons/fa";


const RMajestic = () => {
  return (
    <div className="rabat-small-tour">
    <Header/>
    <section className="rabat-small-tour-heading">
        <h3>MOROCCO MAJESTIC TOUR</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>8 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Visit notorious monuments.</li>
                <li>Visit the Roman ruins of Volubilis.</li>
                <li>Walk inside the ancient walled city of Fes.</li>
                <li>Visit the majestic Hassan 2 mosque.</li>
                <li>Visit the entertaining Jamaa El fna square.</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>Morocco majestic tour is an 8-day travel package that allows you to visit all four imperial cities of Morocco's kingdom. We'll start in Rabat with some sightseeing before heading to Meknes, another imperial city known for its beautiful madrassas. Following that, we will visit the Roman ruins of Volubilis before continuing on to Fes, the spiritual and intellectual capital of Morocco. Finally, before returning to Casablanca, we will visit the beautiful city of Marrakech.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Arrival in Casablanca</p>
                <p className="day-description">When you arrive at the airport, the driver will be waiting for you. You will be transported to your hotel to check in. You will also have time to relax and discover the city on your own.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Casablanca-Rabat</p>
                <p className="day-description">We'll take the highway to Rabat today. We will check you into your hotel and then visit Rabat, Morocco's capital city. We'll visit the Hassan tour, visit the Oudaya kasbah, and see the Mohamed 5 mausoleum, among other things. We will then take you to your lodging so you can rest for the night.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Rabat-Volubilis-Meknes-Fes</p>
                <p className="day-description">Today we will visit Meknes, one of Morocco's four imperial cities. The Almoravids founded the city in the 11th century. The Bou Inania Madrasa, the Moulay Isma'il palace's gateway, and the Bab Mansour are all worth seeing. Then it's on to Volubilis. The archaeological relics at this site bear witness to several civilizations. The site has generated a significant amount of artistic material, including mosaics, marble, and hundreds of other items. Then we'll head to Fes to check into your hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Fes sightseeing</p>
                <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 5: Fes-Beni Mellal-Marrakech</p>
                <p className="day-description">Today, we travel to Marrakech via Ifrane and Beni Mella, two of the kingdom's most important agricultural cities. We'll eat lunch here before continuing on to Marrakech.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 6: Marrakech sightseeing</p>
                <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 7: Marrakech-Casablanca</p>
                <p className="day-description">Today, we'll take the highway to Casablanca. After a three-hour drive, we will arrive in Casablanca to visit the Hassan 2 mosque. One of the largest mosques in the world. We will also visit the Habous district, La corniche of Ain Diab and many more sites.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 8: Departure from Casablanca</p>
                <p>Today, your driver will take you to Casablanca's airport to catch your flight. And the tour will come to an end here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>7 overnights in selected accomodations with breakfast</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Official guided tour of Marrakech and Fes</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch, dinners and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Monuments fees</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default RMajestic