import React from 'react'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

import '../views/Terms.css'

const TermsConditions = () => {
  return (
    <section className="terms">
        <Header/>
        <article className="terms-content">
            <h4>Terms and conditions</h4>
            <h6>Assets and images</h6>
            <ul>
                <li>All assets and images are not to be downloaded/screenshot and sold.</li>
                <li>All assets and images are downloaded from unsplash, therefore they are all under unsplash free license.</li>
                <li><a href="https://unsplash.com/license">Click here to learn more about unsplash free license</a>.</li>
            </ul>
        </article>
        <Footer/>
    </section>
  )
}

export default TermsConditions