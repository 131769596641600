import React from 'react'

import Header from '../../components/Header/Header'
import Heading from '../../components/Service/Heading/Heading'
import Packs from '../../components/Service/Packs/Packs'
import Contact from '../../components/Contact/Contact'
import Footer from '../../components/Footer/Footer'

import '../Services/Services.css'

const Services = () => {
  return (
    <div className="services">
      <Header/>
      <Heading/>
      <Packs/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default Services