import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";


const MFesDesert = () => {
  return (
    <div className="Marrakech-small-tour">
        <Header/>
        <section className="Marrakech-small-tour-heading">
            <h3>MARRAKECH TO FES VIA THE SAHARA DESERT</h3>
        </section>
        <section className="service-info">
            <aside className="service-info-sections">
            <FaClock color="#F24E1E" size="2rem"/>
            <p>3 days</p>
            </aside>
            <aside className="service-info-sections">
            <FaCalendarCheck color="#F24E1E" size="2rem"/>
            <p>Availabale all year</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneArrival color="#F24E1E" size="2rem"/>
            <p>Fes</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneDeparture color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
            <aside className="service-info-sections">
            <FaUsers color="#F24E1E" size="2rem"/>
            <p>8 people max</p>
            </aside>
        </section>
        <section className="description">
            <section className="description-heading">
                <FaBookOpen color="#F24E1E" size="2rem"/>
                <h6>Description</h6>
            </section>
            <section className="description-content">
                <p>Join us on a three-day, two-night trip to the south and north of Morocco, departing from Marrakech. We'll pass through the high Atlas Mountains, visit the photogenic Ait ben Haddou Kasbah, the charming Ouarzazate city, and then head to the majestic Sahara desert for an unforgettable overnight experience before heading north to Ifrane and the infamous city of Fes.</p>
            </section>
        </section>
        <section className="itinary">
            <section className="itinary-heading">
                <FaRoad color="#F24E1E" size="2rem"/>
                <h6>Itinary</h6>
            </section>
            <section className="itinary-content">
                <aside className="day">
                    <p className="day-title">Day 1: Marrakech-Ait Ben Haddou-Ouarzazate-Dades Valley</p>
                    <p className="day-description">We will pick you up from your Marrakech hotel and transport you to the high Atlas mountains. We'll begin in Ait Ben Haddou and visit the famous Kasbah, which has appeared in a number of films and television shows, including Game of Thrones. Then we travel to Ouarzazate to see the Taourirt Kasbah, a ksar that exemplifies fine Moroccan artisanal craftsmanship. Following that, we'll visit the Rose Valley, which hosts the Rose Festival in May, and then the Skoura region, which has over 70,000 palm trees. Finally, we'll arrive in Dades Valley, where you'll check into a traditional hotel.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 2: Dades Valley-Todra Gorge-Jorf-Erfoud-Merzouga</p>
                    <p className="day-description">In the morning, we return through the valley to see the incredible rock formations and the Ait Yul kasbah. We leave the Dades valley and travel to Tinghir before heading to the Todra gorges. We continue on to Erfoud and Rissani for lunch before departing for Merzouga, where you will enjoy a camel ride and spend the night in the desert. Dinner will be served as well.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 3: Merzouga-Rissani-Ziz Valley-Azrou-Ifrane-Fes</p>
                    <p className="day-description">After a peaceful night in the desert, you will see the sunrise from the top of the dunes. We will leave the Sahara desert on camelback after breakfast. Then we drive north through Tafilelt, the world's largest palm grove, to the Ziz Valley, where a High Atlas river waters the palm trees. We can take a break here to enjoy the view from the valley's highest point. We'll then proceed to Midelt, where we'll have lunch. Then we'll visit Azrou's cedar forests. The next stop is Ifrane, a European-style city. Finally, we will drop you off in Fes to conclude your journey.</p>
                </aside>
            </section>

        </section>
        <section className="tour-detail">
                <section className="tour-detail-heading">
                    <FaInfoCircle color="#F24E1E" size="2rem"/>
                    <h6>Includes/Excludes</h6>
                </section>
                <section className="tour-heading-content">
                    <ul>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>2 nights accommodation</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Dinner and breakfast</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Camel ride in the Sahara desert with a camel guide</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Fuels and tolls</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Lunch and drinks</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Monuments fees</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Travel insurance</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Any private expenses</p>
                        </li>
                    </ul>
                    
                </section>
            </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default MFesDesert