import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";


const FMarrakech = () => {
  return (
    <div className="fchefchaouen-small-tour">
    <Header/>
    <section className="fchefchaouen-small-tour-heading">
        <h3>FES TO MARRAKECH DESERT TOUR</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>3 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Marrakech</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Fes</p>
        </aside>
        <aside className="service-info-sections">
        <FaUsers color="#F24E1E" size="2rem"/>
        <p>8 people max</p>
        </aside>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>Join us on a three-day, two-night trip to the north and south of Morocco, departing from Fes. We'll pass through Ifrane, the Sahara desert, Ait Ben Haddou and ouarzazate and finish in the imperial city of Marrakech.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Fes-Ifrane-Azrou-Ziz Valley-Sahara desert</p>
                <p className="day-description">Travel south, passing through Ifrane, Morocco's Switzerland. We'll stop by to photograph the city's European architecture. Then we'll go to Azrou to see the wildlife of the cedar forest, particularly the monkeys. We'll then proceed to Midelt for lunch. We'll pass through Errachia, the Ziz valley and gorges, Aoufous, and Erfoud on our way to the magnificent Merzouga dunes. There you will spend the night in a tent with live Saharan music.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Sahara Desert-Erfoud-Todra gorge-Dades Valley</p>
                <p className="day-description">Get up early to watch the sunrise from the dunes' crest. We will travel to Tineghir via Erfoud and Tinejdad after breakfast. We'll stop for lunch later before heading to the Todra gorges, where you can hike through the geological formations. Then we'll take you to your hotel in the Dades Gorges.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Dades Valley-Ait Ben Haddou Kasbah-Marrakech</p>
                <p className="day-description">On this day, we return to Marrakech via Skoura which is home to over 700,000 palm trees, the Rose Valley which is well known for its rose based products and Ouarzazate to see the Taourirte Kasbah and filming studios where Gladiator, Game of Thrones, and Prison Break were filmed. Finally, we will head to Marrakech passing by Tiz n'Tichka and the majestic high Atlas mountains. We will transfer you to your accommodation and that will be the end of your tour.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>2 overnights in selected accommodation</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Camel ride</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Monuments fees</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default FMarrakech