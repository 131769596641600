import React from 'react'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

const Privacy = () => {
  return (
    <section className="terms">
    <Header/>
    <article className="terms-content">
        <h4>privacy-policies</h4>
        <h6>Data storage</h6>
        <ul>
            <li>We do not store any of your data.</li>
        </ul>
    </article>
    <Footer/>
</section>
  )
}

export default Privacy