import React from 'react'

import Carousel from '../../components/Carousel/Carousel'

import '../modals/Modal.css'

import {FaWindowClose} from "react-icons/fa";


const Modal = props => {

    if (!props.show) {
        return null;
    }

  return (
    <div className="add-modal">
    <div className="add-modal-content">
        <div className="add-modal-header">
            <h6 className="add-modal-title">GALLERY</h6>
            <FaWindowClose onClick={props.onClose} size="8%"/>
        </div>
        <div className="add-modal-body">
        <div className="testimony-row" id="testimony">
            <div className="testimony-carousel">
              <Carousel/>
            </div>
        </div>
        </div>
    </div>
</div>
  )
}

export default Modal