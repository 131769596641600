import React from 'react'

import '../../components/Values/Values.css'

import {FaCheckCircle} from "react-icons/fa";

const Values = () => {
  return (
    <article className="values">
        <h6>OUR VALUES</h6>
        <aside className="first-line">
            <article className="first-elemnt">
                <FaCheckCircle color="#F24E1E" size="4rem"/> 
                <br />
                <small className="value-title">Flexibility</small>
                <p>The services we offer to our clients can be flexible and tailored to their specific needs.</p>
            </article>
            <article className="second-elemnt">
                <FaCheckCircle color="#F24E1E" size="4rem"/>
                <br />
                <small className="value-title">Cost</small>
                <p>We provide services with a range of prices that fit all budgets.</p>
            </article>
        </aside>
        <aside className="second-line">
            <article className="first-elemnt">
                <FaCheckCircle color="#F24E1E" size="4rem"/>
                <br />
                <small className="value-title">Security</small>
                <p>In order for our customers to feel secure and at ease throughout their journey, we guarantee safety and security precautions.</p>
            </article>
            <article className="second-elemnt">
                <FaCheckCircle color="#F24E1E" size="4rem"/>
                <br />
                <small className="value-title">Fun</small>
                <p>Our customers are friends to us. On our excursions, we focus on having a good time together while experiencing new things.</p>
            </article>
        </aside>
    </article>
  )
}

export default Values