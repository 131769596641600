import React from 'react'

import Header from '../../../components/Header/Header'
import Contact from '../../../components/Contact/Contact'
import Footer from '../../../components/Footer/Footer'

import '../Casablanca/Casablanca.css'

import fdesert from '../../../assets/fdesert.jpg'
import casablanca from '../../../assets/casablanca.jpg'
import fez from '../../../assets/fez.jpg'
import tgtour from '../../../assets/tgtour.jpg'
import casa_ouarzazate from '../../../assets/casa-ouarzazate.jpg'
import casa_mosque from '../../../assets/casa-mosque.jpg'
import casa_mosque_gate from '../../../assets/casa-mosque-gate.jpg'

const Casablanca = () => {
  return (
    <div className="casablanca">
        <Header/>
        <section className="casablanca-heading">
            <h3>CASABLANCA TOURS</h3>
        </section>
        <section className="casablanca-packs">
          <section className="casablanca-first-line">
                    <article className="casablanca-pack">
                      <aside className="casablanca-photo">
                          <img src={fdesert} alt="desert" width="290px" height="260px" />
                      </aside>
                      <aside className="casablanca-content">
                          <h6>Moroccan panorama tour</h6>
                          <p>A 14 days and 13 nights trip across the country.</p>
                          <a href="/rabat-panorama-tour">
                              <button className="casablanca-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                          </a>
                      </aside>
                  </article>

                  <article className="casablanca-pack">
                      <aside className="casablanca-photo">
                          <img src={casablanca} alt="casablanca" width="290px" height="260px" />
                      </aside>
                      <aside className="casablanca-content">
                          <h6>Majestic Morocco tour from Casablanca</h6>
                          <p>8 days trip around the kingdom starting from Casablanca.</p>
                          <a href="/rabat-majestic-tour">
                              <button className="casablanca-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                          </a>
                      </aside>
                  </article>
              </section>

              <section className="casablanca-first-line">
                    <article className="casablanca-pack">
                      <aside className="casablanca-photo">
                          <img src={fez} alt="fez" width="290px" height="260px" />
                      </aside>
                      <aside className="casablanca-content">
                          <h6>Private Casablanca tour</h6>
                          <p>A one week trip from Casablanca to imperial cities and Sahara desert.</p>
                          <a href="/casablanca-private-tour">
                              <button className="casablanca-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                          </a>
                      </aside>
                  </article>

                  <article className="casablanca-pack">
                      <aside className="casablanca-photo">
                          <img src={tgtour} alt="desert" width="290px" height="260px" />
                      </aside>
                      <aside className="casablanca-content">
                          <h6>Casablanca Grand tour</h6>
                          <p>A two weeks trip around the country starting from Casablanca.</p>
                          <a href="/casablanca-grand-tour">
                              <button className="casablanca-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                          </a>
                      </aside>
                  </article>
              </section>

              <section className="casablanca-first-line">
                  <article className="casablanca-pack">
                      <aside className="casablanca-photo">
                          <img src={casa_mosque_gate} alt="ait ben Haddou" width="290px" height="260px" />
                      </aside>
                      <aside className="casablanca-content">
                          <h6>Morocco highlights tour</h6>
                          <p>A 9 days trip across the country.</p>
                          <a href="/casablanca-highlights-tour">
                              <button className="casablanca-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                          </a>
                      </aside>
                  </article>

                <article className="casablanca-pack">
                      <aside className="casablanca-photo">
                          <img src={casa_ouarzazate} alt="ouarzazate" width="290px" height="260px" />
                      </aside>
                      <aside className="casablanca-content">
                          <h6>Casablanca imperial cities tour package</h6>
                          <p>8 days trip across the country, starting from Casabalanca to imperial cities.</p>
                          <a href="/casablanca-imperial-cities-tour-package">
                              <button className="casablanca-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                          </a>
                      </aside>
                  </article>
                  
              </section>

              <section className="casablanca-first-line">
                  <article className="casablanca-pack">
                      <aside className="casablanca-photo">
                          <img src={casa_mosque} alt="Hassan 2 mosque" width="290px" height="260px" />
                      </aside>
                      <aside className="casablanca-content">
                          <h6>Extended Casablanca private tour package</h6>
                          <p>10 days private tour packages starting from Casablanca to imperial cities and the Sahara desert.</p>
                          <a href="/casablanca-extended-tour">
                              <button className="casablanca-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                          </a>
                      </aside>
                  </article>
              </section>
           
            
        </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Casablanca