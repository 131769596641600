import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";


const MDesertTrip = () => {
  return (
    <div className="Marrakech-small-tour">
        <Header/>
        <section className="Marrakech-small-tour-heading">
            <h3>FIVE DAYS DESERT TOUR</h3>
        </section>
        <section className="service-info">
            <aside className="service-info-sections">
            <FaClock color="#F24E1E" size="2rem"/>
            <p>5 days</p>
            </aside>
            <aside className="service-info-sections">
            <FaCalendarCheck color="#F24E1E" size="2rem"/>
            <p>Availabale all year</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneArrival color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneDeparture color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
            <aside className="service-info-sections">
            <FaUsers color="#F24E1E" size="2rem"/>
            <p>8 people max</p>
            </aside>
        </section>
       
        <section className="description">
            <section className="description-heading">
                <FaBookOpen color="#F24E1E" size="2rem"/>
                <h6>Description</h6>
            </section>
            <section className="description-content">
                <p>Five days desert trip is a tour that focuses on the southern region of Morocco. Throughout this journey, you will stop in many southern cities and regions, most notably Ouarzazate in the Sahara desert, for fun activities. Witness the splendor of the palm-filled oasis. Visit the kasbah, which will transport you to ancient times.</p>
            </section>
        </section>
        <section className="itinary">
            <section className="itinary-heading">
                <FaRoad color="#F24E1E" size="2rem"/>
                <h6>Itinary</h6>
            </section>
            <section className="itinary-content">
                <aside className="day">
                    <p className="day-title">Day 1: Marrakech-Ait Ben Haddou-Ouarzazate-Dades Valley</p>
                    <p className="day-description">We will pick you up from your hotel in Marrakech and take you to the high Atlas mountains. We'll begin in Ait Ben Haddou, where we'll see the famous Kasbah, which has appeared in a number of films and television shows, including Game of Thrones. Then we head to Ouarzazate to see the Taourirt Kasbah, a ksar that exemplifies fine Moroccan artisanal work. Following that, we will travel to the Rose Valley, which hosts the Rose Festival in May, and then to the Skoura region, which is home to over 7000,000 palm trees. Finally we reach the Dades valley where you will check in a traditional hotel.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 2: Dades Valley-Todra Gorge-Jorf-Erfoud-Merzouga</p>
                    <p className="day-description">We return through the valley in the morning to see the incredible rock formations and the Ait Yul kasbah. We leave the Dades valley and make our way to Tinghir before continuing on to the Todra gorges. After a short walk through the Gorge, we continue on to Erfoud and Rissani for lunch before departing for Merzouga. We'll stay in a hotel close to the sand dunes.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 3: Merzouga-Hassi Labied-Khamlia-Nomad family-Fossil site-Tour of the dunes-Sahara desert</p>
                    <p className="day-description">Following breakfast, you will embark on a 4x4 adventure through the Sahara desert, stopping to see volcanic rocks, an authentic nomad family, and the Khamlia village with Gnawa music entertainment. Then we'll go to Lake Sarji, which forms in the spring and attracts a wide range of wildlife. We'll then have lunch in Mezroouga or Hassi Labied. Following lunch, you'll be able to see the canal irrigation system and walk through the oasis at the dunes' foot. In the afternoon, get ready for an unforgettable 1-hour guided camel trek that will transport you to ancient times. You will ride a dromedary through the Sahara desert before arriving at your overnight camp and spending the night in a tent.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 4: Sahara desert-Draa valley-Zagora</p>
                    <p className="day-description">You will see the sunrise on top of the dunes after a peaceful night in the desert. After breakfast, we will travel to Rissani, where you can add the mausoleum of Moulay Ali Cherif, the founder of the Alaouite Dynasty, to your itinerary. We'll stop for lunch in Nkoub before heading to Zagoura for the night.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 5: Zagora-Draa valley-Kasbah Telouet-Marrakech</p>
                    <p className="day-description">We will leave Zagoura via Agdez and the Draa Valley. Our journey crosses the Draa river, one of the kingdom's largest rivers. Later, we'll travel to Marrakech, stopping in Telouet, a traditional High Atlas berber village. We will stop here to visit the Kaskbah El Glaoui before continuing on to Marrakech, where your driver will drop you off at your hotel. And that will be the end of the tour.</p>
                </aside>
                
            </section>

        </section>
        <section className="tour-detail">
                <section className="tour-detail-heading">
                    <FaInfoCircle color="#F24E1E" size="2rem"/>
                    <h6>Includes/Excludes</h6>
                </section>
                <section className="tour-heading-content">
                    <ul>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Selected accomodations: 4 overnights with breakfast and dinner</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Camel ride in the Sahara desert with a camel guide</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Professional english speaking driver</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Lunch and drinks</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Monuments fees</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Travel insurance</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Any private expenses</p>
                        </li>
                    </ul>
                    
                </section>
            </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default MDesertTrip