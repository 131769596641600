import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";

const CGrandTour = () => {
  return (
    <div className="cprivate-small-tour">
    <Header/>
    <section className="cprivate-small-tour-heading">
        <h3>CASABLANCA GRAND TOUR</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>15 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
        <aside className="service-info-sections">
        <FaUsers color="#F24E1E" size="2rem"/>
        <p>8 people max</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Stroll around the colorful souks in the imperial cities</li>
                <li>Take beautiful views of the Atlantic Ocean</li>
                <li>Watch the funny tree climibing goats</li>
                <li>Wander the colorful streets of Asilah</li>
                <li>Enjoy a jeep ride to the desert dunes and visit a nomad family</li>
                <li>Take the 1001 kasbahs road</li>
                <li>Visit the beautiful city of Chefchaouen</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>Casablanca Grand Tour is a 15-day trip that allows our valued customers to visit imperial cities of the kingdom, have a full Sahara experience, visit Taroudant, a town known for its high quality saffron, and visit the coastal cities of Agadir and Essaouira before arriving in Marrakech.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Arrival in Casablanca</p>
                <p className="day-description">When you arrive at the airport, the driver will be waiting for you. You will be transported to your hotel to check in. You will also have time to relax and discover the city and visit the great Hassan 2 mosque.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Casablanca-Rabat-Asilah</p>
                <p className="day-description">We'll take the highway to Rabat today. We will check you into your hotel and then visit Rabat, Morocco's capital city. We'll visit the Hassan tour, visit the Oudaya kasbah, and see the Mohamed 5 mausoleum, among other sites. Then we head to Asilah a city located in the north of the kingdom. This city is well known for its street art work as famous artists visit the city to leave their mark on the street walls. You will get to visit the Skalla fortress. Here, you will spend the night in a riad or hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Asilah-Tangier-Chefchaouen</p>
                <p className="day-description">This morning, immediately after breakfast, we will depart for the magical blue city of Chefchaouen. Explore its narrow alleys and capture one-of-a-kind memories and photographs. Visit a farm to purchase a local product such as goat cheese. At night we recommend you have dinner in the Outa El Hammam square. Overnight in a riad or hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Chefchaouen-Volubilis-Meknes-Fes</p>
                <p className="day-description">We're off to Volubilis today. This site's archaeological relics bear witness to several civilizations. From prehistory to the Islamic period, all ten centuries of occupation are covered. The site has produced a large amount of artistic material, such as mosaics, marble, and hundreds of other items. Then we'll go to Meknes, one of Morocco's four imperial cities. The Almoravids established this city in the 11th century. The Bou Inania Madrasa, the Moulay Isma'il palace's gateway, and the Bab Mansour are all worth seeing. Finally, spiritual and intellectual capital, Fes, will be reached. You'll be checking into your hotel. The old city and souks are the best places to visit in the afternoon.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 5: Fes sightseeing</p>
                <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 6: Fes-Ifrane-Azrou-Midelt-Ziz Valley-Merzouga</p>
                <p className="day-description">Travel south, passing through Ifrane, Morocco's Switzerland. We'll stop by to photograph the city's European architecture. Then we'll go to Azrou to see the wildlife of the cedar forest, particularly the monkeys. We'll then proceed to Midelt for lunch. We'll pass through Errachia, the Ziz valley and gorges, Aoufous, and Erfoud on our way to the magnificent Merzouga dunes. We spend the night here.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 7: Merzouga-Hassi Labied-Khamlia-Nomad family-Fossil site-Tour of the dunes-Sahara desert</p>
                <p className="day-description">Following breakfast, you will embark on a 4x4 adventure through the Sahara desert, stopping to see volcanic rocks, an authentic nomad family, and the Khamlia village with Gnawa music entertainment. Then we'll go to Lake Sarji, which forms in the spring and attracts a wide range of wildlife. We'll then have lunch in Mezroouga or Hassi Labied. Following lunch, you'll be able to see the canal irrigation system and walk through the oasis at the dunes' foot. In the afternoon, get ready for an unforgettable 1-hour guided camel trek that will transport you to ancient times. You will ride a dromedary through the Sahara desert before arriving at your overnight camp and spending the night in a tent.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 8: Sahara Desert-Erfoud-Todra gorge-Dades Valley</p>
                <p className="day-description">Get up early to watch the sunrise from the dunes' crest. We will travel to Tineghir via Erfoud and Tinejdad after breakfast. We'll stop for lunch later before heading to the Todra gorges, where you can hike through the geological formations. Then we'll take you to your hotel in the Dades Gorges.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 9: Ouarzazate-Ait ben Haddou</p>
                <p className="day-description">Today we learn more about the imblematic Ait Ben Haddou and Ouarzazate. Here we will see the Taourirte Kasbah and filming studios where Gladiator, Game of Thrones, and Prison Break were filmed. Free time and overnight here.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 10: Ait ben Haddou-Taroudant</p>
                <p className="day-description">After breakfast, we will drive to Taroudant, passing by the lower edge of the Atlas Mountains. We arrive in Taroudante, a city known for its fertile soil and as the home of Morocco's finest saffron. Following that, we will take you to your accomodation. Free time and overnight.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 11: Taroudant-Souss Moussa national park-Agadir</p>
                <p className="day-description">On this day, we travel to Souss-Massa National Park, which is located 60 kilometers south of Agadir and 40 kilometers north of Tiznit. The entrance is located at the far end of Sidi-Bnazern village. This 33,800-hectare nature reserve is home to mongooses, jackals, wild boars, and gazelles, as well as gulls, falcons, cormorants, and herons. Later, we continue to Agadir, a resort town with numerous restaurants and hotels. We will help you check into your accomodation. Overnight in hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 12: Trip to Essaouira</p>
                <p className="day-description">On this day, we'll stop on the way to Essaouira to see the goats on top of the iconic Argan trees. We will also have the opportunity to visit a local feminine argan cooperative and observe the argan oil production process. Later, we will arrive in Essaouira. You can go sightseeing in this small but charming city. We'll go to the ports to see the day's catch and eat a delicious sea food lunch at a local restaurant. Overnight here in a traditional riad or hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 13: Essaouira-Marrakech</p>
                <p className="day-description">After breakfast, we depart to the red city of Marrakech. We will help you check into your accommodation. Free time and overnight here.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 14: Marrakech sightseeing</p>
                <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 15: Marrakech-Casablanca airport</p>
                <p className="day-description">Today, your driver will take you to Casablanca's airport to catch your flight. And the tour will come to an end here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>14 nights accommodation in selected accommodations with breakfast</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>3 dinners (Sahara diesert & Dades valley)</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Professional english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Guided tour in the city of Fes and Marrakech</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Tickets fees to the sites</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default CGrandTour