import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";

const MBestMorocco = () => {
  return (
    <div className="Marrakech-small-tour">
        <Header/>
        <section className="Marrakech-small-tour-heading">
            <h3>MARRAKECH BEST OF TOUR</h3>
        </section>
        <section className="service-info">
            <aside className="service-info-sections">
            <FaClock color="#F24E1E" size="2rem"/>
            <p>12 days</p>
            </aside>
            <aside className="service-info-sections">
            <FaCalendarCheck color="#F24E1E" size="2rem"/>
            <p>Availabale all year</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneArrival color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneDeparture color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
            <aside className="service-info-sections">
            <FaUsers color="#F24E1E" size="2rem"/>
            <p>8 people max</p>
            </aside>
        </section>
        <section className="hightlights">
            <section className="hightlight-heading">
                <FaLightbulb color="#F24E1E" size="2rem"/>
                <h6>Highlights</h6>
            </section>
            <section className="hightlight-list">
                <ul>
                    <li>Walk through the maze-like streets of Marrakech and Fes.</li>
                    <li>Visit Asilah, the crown jewel of the kingdom's north.</li>
                    <li>Explore the vast Sahara desert on a camel ride.</li>
                    <li>Make a stop at the fortified city of El Jadida.</li>
                    <li>Enjoy the charming blue city of Chefchaouen.</li>
                    <li>Travel the 1001 Kasbah Road.</li>

                </ul>
            </section>
        </section>
        <section className="description">
            <section className="description-heading">
                <FaBookOpen color="#F24E1E" size="2rem"/>
                <h6>Description</h6>
            </section>
            <section className="description-content">
                <p>Morocco Grand Tour is a 10-day tour that begins in Marrakech and includes visits to Morocco's imperial cities, Chefchaouen, and the Sahara desert. We will begin our 10-day journey in Marrakech and then travel to Casablanca to see the Hassan 2 mosque. Then we'll visit Rabat, the blue city of Chefchaouen, and return to the south for a one-of-a-kind Sahara desert experience.</p>
                <p>Our 10-day journey begins in Marrakech and continues to Casablanca, where we will visit the Hassan 2 mosque. Then we'll go to Rabat and Chefchaouen. We'll also have the opportunity to visit Volubilis, an ancient archeological Roman site. After that, we'll visit two of Morocco's most important and oldest cities, Meknes and Fes, before heading to the breathtaking Sahara desert to see the Dades gorges, Ouarzazate, and Ait Ben Haddou Kasba. Then we'll head back to the red city of Marrakech.</p>
            </section>
        </section>
        <section className="itinary">
            <section className="itinary-heading">
                <FaRoad color="#F24E1E" size="2rem"/>
                <h6>Itinary</h6>
            </section>
            <section className="itinary-content">
                <aside className="day">
                    <p className="day-title">Day 1: Arrival in Marrakech</p>
                    <p className="day-description">When you arrive at the airport, the driver will be waiting for you. You will be transported to your hotel to check in. You will also have time to relax and visit Jammaa El Fna Square.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 2: Marrakech sightseeing</p>
                    <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 3: Marrakech-High Atlas Mountains-Ait Ben Haddou-Ouarzazate-Dades Gorges</p>
                    <p className="day-description">We will pick you up from your Marrakech hotel and transport you to the high Atlas mountains. We'll start in Ait Ben Haddou and visit the famous Kasbah, which has appeared in a number of films and television shows, including Game of Thrones. After that, we'll travel to Ouarzazate to see the Taourirt Kasba. Following that, we'll go to the Rose Valley, which hosts the Rose Festival in May, and then to the Skoura region, which has over 700,000 palm trees. We'll arrive in Dades Valley and you'll spend the night.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 4: Dades Valley-Todra Gorge-Jorf-Erfoud-Merzouga-Sahara desert</p>
                    <p className="day-description">In the morning, we return through the valley to see the incredible rock formations and the Ait Yul kasbah. We leave the Dades valley and travel to Tinghir before heading to the Todra gorges. We continue on to Erfoud and Rissani for lunch before departing for Merzouga after a short walk through the Gorge. Prepare for an unforgettable 1-hour guided camel trek that will transport you to ancient times in the afternoon. You'll travel through the Sahara desert on a dromedary before arriving at your overnight camp and sleeping under the stars in a tent.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 5: Sahara desert-Rissani-Ziz Valley-Azrou-Ifrane-Fes</p>
                    <p className="day-description">We drive north through Tafilelt, the world's largest palm grove, after watching the sunrise on the sand dunes and eating breakfast. This leads to the Ziz Valley, where a river from the High Atlas flows down to water the palm trees. We can pause here to take in the view from the valley's highest point. We then proceed to Midelt, where we will stop for lunch. Then we continue on to Azrou's cedar forests to see the wildlife. The following destination is Ifrane, an architecturally European city. Finally, we arrive in Fes, where we will check in and spend the night.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 6: Fes sightseeing</p>
                    <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 7: Fes-Chefchaouen</p>
                    <p>Traveling north, we'll go to Chefchaouen, a lovely blue city. Explore this photogenic city's narrow streets. Unwind in the </p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 8: Chefchaouen-Tetouane-Asilah</p>
                    <p className="day-description">After breakfast, we will travel through the beautiful landscape to the city of Asilah. When you arrive, you will have the opportunity to explore the city. Asilah is famous for its street art. Many city walls are covered in art by famous artists. Before checking into your accommodation, you will also visit the Skalia fortress for an amazing view of the medina and the sea.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 9: Asilah-Rabat-Casablanca</p>
                    <p className="day-description">We'll travel to Rabat, the kingdom's capital. We will stop to see the Oudaya Kasbah and the Hassan Tower, as well as Chellah. We will continue our journey to Casablanca to see the magnificent Hassan 2 mosque, one of the largest in the world. We'll spend the night in Casablanca.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 10: Casablanca-El Jadida-Essaouira</p>
                    <p className="day-description">We will depart in the morning for El Jadid, a city once controlled by the Portuguese, who left their mark on the architecture of the old city. We will continue to Essaouira, a previously Portuguese-controlled city as well. Take in the fresh sea air and observe the bustling fish market.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 11: Essaouira-Marrakech</p>
                    <p className="day-description">Depart from Essaouira for the red city of Marrakech in the morning. We'll have the opportunity to stop by an argan feminine cooperative on the way.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 12: Departure from Marrakech</p>
                    <p className="day-description">Today, your driver will take you to Marrakech's airport to catch your flight. And the tour will come to an end here.</p>
                </aside>
            </section>

        </section>
        <section className="tour-detail">
                <section className="tour-detail-heading">
                    <FaInfoCircle color="#F24E1E" size="2rem"/>
                    <h6>Includes/Excludes</h6>
                </section>
                <section className="tour-heading-content">
                    <ul>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>3 starts hotels or riads: 11 overnights with breakfast</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>2 dinners (Sahara desert and Dades valley)</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Camel ride in the Sahara desert with a camel guide</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Official guided tour of Marrakech and Fes</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Fuels and tolls</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Lunch and drinks</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Monuments fees</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Travel insurance</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Any dinners or lunches where not mentioned</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Any private expenses</p>
                        </li>
                    </ul>
                    
                </section>
            </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default MBestMorocco