import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";

const MGrandTour = () => {
  return (
    <div className="Marrakech-small-tour">
        <Header/>
        <section className="Marrakech-small-tour-heading">
            <h3>MARRAKECH GRAND TOUR</h3>
        </section>
        <section className="service-info">
            <aside className="service-info-sections">
            <FaClock color="#F24E1E" size="2rem"/>
            <p>10 days</p>
            </aside>
            <aside className="service-info-sections">
            <FaCalendarCheck color="#F24E1E" size="2rem"/>
            <p>Availabale all year</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneArrival color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneDeparture color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
            <aside className="service-info-sections">
            <FaUsers color="#F24E1E" size="2rem"/>
            <p>8 people max</p>
            </aside>
        </section>
        <section className="hightlights">
            <section className="hightlight-heading">
                <FaLightbulb color="#F24E1E" size="2rem"/>
                <h6>Highlights</h6>
            </section>
            <section className="hightlight-list">
                <ul>
                    <li>Feel the vibe of the old medina as you walk in its ancient walls.</li>
                    <li>Visit the majestic Hassan 2 mosque.</li>
                    <li>Enjoy the charming blue city of Chefchaouen.</li>
                    <li>Explore the vast Sahara desert on a camel ride.</li>
                    <li>Drive by kasbahs, canyons and see the impressive Tiz n' Tichka.</li>
                </ul>
            </section>
        </section>
        <section className="description">
            <section className="description-heading">
                <FaBookOpen color="#F24E1E" size="2rem"/>
                <h6>Description</h6>
            </section>
            <section className="description-content">
                <p>Morocco Grand Tour is a 10-day tour that begins in Marrakech and includes visits to Morocco's imperial cities, Chefchaouen, and the Sahara desert. We will begin our 10-day journey in Marrakech and then travel to Casablanca to see the Hassan 2 mosque. Then we'll visit Rabat, the blue city of Chefchaouen, and return to the south for a one-of-a-kind Sahara desert experience.</p>
                <p>Our 10-day journey begins in Marrakech and continues to Casablanca, where we will visit the Hassan 2 mosque. Then we'll go to Rabat and Chefchaouen. We'll also have the opportunity to visit Volubilis, an ancient archeological Roman site. After that, we'll visit two of Morocco's most important and oldest cities, Meknes and Fes, before heading to the breathtaking Sahara desert to see the Dades gorges, Ouarzazate, and Ait Ben Haddou Kasba. Then we'll head back to the red city of Marrakech.</p>
            </section>
        </section>
        <section className="itinary">
            <section className="itinary-heading">
                <FaRoad color="#F24E1E" size="2rem"/>
                <h6>Itinary</h6>
            </section>
            <section className="itinary-content">
                <aside className="day">
                    <p className="day-title">Day 1: Arrival in Marrakech</p>
                    <p className="day-description">When you arrive at the airport, the driver will be waiting for you. You will be transported to your hotel to check in. You will also have time to relax and visit Jammaa El Fna Square.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 2: Marrakech sightseeing</p>
                    <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 3: Marrakech-Casablanca-Rabat</p>
                    <p className="day-description">Today, we'll take the highway to Casablanca. After a three-hour drive, we will arrive in Casablanca to visit the Hassan 2 mosque. One of the largest mosques in the world. Then we'll continue on to Rabat. When we arrive, you will check into your hotel, and we will then visit Rabat, Morocco's capital city. We will see the Hassan tour, the Oudaya kasbah, the Mohamed 5 mausoleum, and other sights...</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 4: Rabat-Chefchaouen</p>
                    <p className="day-description">We'll travel to the lovely blue city of Chefchaouen. Explore the photogenic city's narrow streets. Unwind in the square...</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 5: Chefchaouen-Volubilis-Meknes-Fes</p>
                    <p className="day-description">Today we travel to Volubilis. The archaeological relics of this site testify to several civilizations. All ten centuries of occupation are covered, from prehistory to the Islamic period. The site has yielded a significant amount of artistic material, including as mosaics, marble, and hundreds of other items. <br/> Following that, we'll travel to Meknes, one of Morocco's four imperial cities. In the 11th century, the Almoravids founded the city. The Bou Inania Madrasa, the gateway to the Moulay Isma'il palace, and the Bab Mansour are all worth seeing. <br/> Finally, the spiritual and intellectual capital will be attained. Fes. You will check into your hotel. In the afternoon, the old city and souks are the best places to visit.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 6: Fes sightseeing</p>
                    <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 7: Fes-Ifrane-Azrou-Midelt-Merzouga-Sahara Desert</p>
                    <p className="day-description">Travel south, passing through Ifrane, Morocco's Switzerland. We'll stop by to photograph the city's European architecture. Then we'll go to Azrou to see the wildlife of the cedar forest, particularly the monkeys. We'll then proceed to Midelt for lunch. We'll pass through Errachia, the Ziz valley and gorges, Aoufous, and Erfoud on our way to the magnificent Merzouga dunes.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 8: Sahara Desert-Erfoud-Todra gorge-Dades Valley</p>
                    <p className="day-description">Get up early to watch the sunrise from the dunes' crest. We will travel to Tineghir via Erfoud and Tinejdad after breakfast. We'll stop for lunch later before heading to the Todra gorges, where you can hike through the geological formations. Then we'll take you to your hotel in the Dades Gorges.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 9: Dades Valley-Ait Ben Haddou Kasbah-Marrakech</p>
                    <p className="day-description">On this day, we return to Marrakech via the Rose Valley and Ouarzazate to see the Taourirte Kasbah and filming studios where Gladiator, Game of Thrones, and Prison Break were filmed. Finally, we will return to Marrakech.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 10: Departure from Marrakech</p>
                    <p className="day-description">Today, your driver will take you to Marrakech's airport to catch your flight. And the tour will come to an end here.</p>
                </aside>
            </section>

        </section>
        <section className="tour-detail">
                <section className="tour-detail-heading">
                    <FaInfoCircle color="#F24E1E" size="2rem"/>
                    <h6>Includes/Excludes</h6>
                </section>
                <section className="tour-heading-content">
                    <ul>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>3 starts hotels or riads: 9 overnights with breakfast</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>2 dinners</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Camel ride in the Sahara desert with a camel guide</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Official guided tour of Marrakech and Fes</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Fuels and tolls</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Lunch and dinner</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Monuments fees</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Travel insurance</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Any dinners or lunches where not mentioned</p>
                        </li>
                    </ul>
                    
                </section>
            </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default MGrandTour