import React from 'react'


import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";

const MSouth = () => {
  return (
    <div className="Marrakech-small-tour">
        <Header/>
        <section className="Marrakech-small-tour-heading">
            <h3>MARRAKECH TO THE SOUTH</h3>
        </section>
        <section className="service-info">
            <aside className="service-info-sections">
            <FaClock color="#F24E1E" size="2rem"/>
            <p>4 days</p>
            </aside>
            <aside className="service-info-sections">
            <FaCalendarCheck color="#F24E1E" size="2rem"/>
            <p>Availabale all year</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneArrival color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneDeparture color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
        </section>
        <section className="hightlights">
            <section className="hightlight-heading">
                <FaLightbulb color="#F24E1E" size="2rem"/>
                <h6>Highlights</h6>
            </section>
            <section className="hightlight-list">
                <ul>
                    <li>Travel the 1001 Kasbah Road.</li>
                    <li>Spend the night in the desert inside a tent.</li>
                    <li>Take a camel ride like a nomad.</li>
                </ul>
            </section>
        </section>
        <section className="description">
            <section className="description-heading">
                <FaBookOpen color="#F24E1E" size="2rem"/>
                <h6>Description</h6>
            </section>
            <section className="description-content">
                <p>Marrakech to the South is a four-day, three-night tour of the kingdom's south. It's also a tour designed for those who want to see the southern region in a short amount of time. During the tour, you will visit the Merzouga Sahara desert and participate in outdoor activities such as camel rides and quad bikes.</p>
            </section>
        </section>
        <section className="itinary">
            <section className="itinary-heading">
                <FaRoad color="#F24E1E" size="2rem"/>
                <h6>Itinary</h6>
            </section>
            <section className="itinary-content">
                <aside className="day">
                    <p className="day-title">Day 1: Marrakech-High Atlas Mountains-Dades Gorges</p>
                    <p className="day-description">We will pick you up from your hotel in Marrakech and take you to the high Atlas mountains. The scenic drive will allow you to see many Kasbahs. Then we'll go through Ouarzazate, the Rose Valley, and the Skoura region, which has a large palmeraie. We'll finally arrive in Dades Valley, where you'll spend the night.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 2: Dades Gorges-Todra Gorges-Merzouga</p>
                    <p className="day-description">We leave the Dades Valley early in the morning and travel along the 1001 kasbah road, passing through vibrant berber villages. We'll then stop in Tinghir before heading to the Todra gorges for a short hike. After that, we'll have lunch in Erfoud before heading to the magnificent Mezrouga desert for camel rides. We go to the night camp after watching the sunset for dinner and live music before calling it a night in the tents.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 3: Merzouga-Ouarzazate</p>
                    <p className="day-description">You will see the sunrise from the top of the dunes after a peaceful night in the desert. After breakfast, we will travel to Rissani to see the mausoleum of Moulay Ali Cherif, the Alaouite Dynasty's founder. We'll stop here for a fossil workshop before continuing on to Agdez and the Draa Valley. We'll eventually arrive in Ouarzazate for dinner and the night.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 4: Ouarzazate-Ait ben Haddou-Marrakech</p>
                    <p className="day-description">We return to Marrakech on this day, passing through the Ait ben Haddou kasbah and Ouarzazate to see the filming locations for Gladiator, Game of Thrones, Prison Break, and many other shows. Finally, we'll return to Marrakech.</p>
                </aside>
            </section>

        </section>
        <section className="tour-detail">
                <section className="tour-detail-heading">
                    <FaInfoCircle color="#F24E1E" size="2rem"/>
                    <h6>Includes/Excludes</h6>
                </section>
                <section className="tour-heading-content">
                    <ul>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>3 nights accommodation</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Professional english speaking driver</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Dinner and breakfast</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Camel ride in the Sahara desert with a camel guide</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Lunch and drinks</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Tickets fees to the sites</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Travel insurance</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Any private expenses</p>
                        </li>
                    </ul>
                    
                </section>
            </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default MSouth