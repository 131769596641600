import React from 'react'

import { useState } from 'react'

import GalleryModal from '../../utils/modals/Modal'

import '../../components/Gallery/Gallery.css'

import {FaPhotoVideo} from "react-icons/fa";

import thumbnail from '../../assets/collection/thumbnail.jpeg'



const Gallery = () => {

  const [show, setShow] = useState(false)

  return (
    <section className="gallery">
        <aside className="gallery-left">
          <img src={thumbnail} alt="thumbnail" width="450px" className="gallery-thumbnail" />
        </aside>
        <aside className="gallery-right">
          <section className="gallery-title">
            <FaPhotoVideo color="#F5F5F5" size="2.5rem"/>
            <h6>GALLERY</h6>
          </section>
          <p>Discover some of our beautiful memories with our dear clients.</p>
          <button className="modal-btn" onClick={() => setShow(true)}>DISPLAY GALLERY</button>
          <GalleryModal onClose= {()=> setShow(false)} show={show}/>
        </aside>
    </section>
  )
}

export default Gallery