import React from 'react'

import Header from "../../components/Header/Header"
import Presentation from '../../components/presentation/Presentation'
import Gallery from '../../components/Gallery/Gallery'
import Team from '../../components/Team/Team'
import Values from '../../components/Values/Values'
import Contact from '../../components/Contact/Contact'
import Footer from '../../components/Footer/Footer'

import '../aboutus/Aboutus.css'

const Aboutus = () => {
  return (
    <div className="aboutus">
        <Header/>
        <Presentation/>
        <Gallery/>
        <Team/>
        <Values/>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Aboutus