import React from 'react'

import '../../../components/Service/Packs/Packs.css'

import Marrakech from '../../../assets/Marrakech.jpg'
import Rabat from '../../../assets/Rabat.jpg'
import Tangier from '../../../assets/Tangier.jpg'
import Fes from '../../../assets/Fes.jpg'
import Casablanca from '../../../assets/casablanca.jpg'

const Packs = () => {
  return (
    <section className="services-packs">
        <article className="pack">
            <aside className="photo">
                <img src={Marrakech} alt="Marrakech" width="250px" height="230px" />
            </aside>
            <aside className="content">
                <h6>Marrakech</h6>
                <p>Discover our 7 tours in the red city.</p>
                <a href="/marrakech-tours">
                    <button className="pack-detail-btn">VIEW ALL TOURS FOR MARRAKECH</button>
                </a>
            </aside>
        </article>

        <article className="pack">
            <aside className="photo">
                <img src={Rabat} alt="Rabat" width="250px" height="230px" />
            </aside>
            <aside className="content">
                <h6>Rabat</h6>
                <p>Discover our 5 tours in the capital.</p>
                <a href="/rabat-tours">
                    <button className="pack-detail-btn">VIEW ALL TOURS FOR RABAT</button>
                </a>
            </aside>
        </article>

        <article className="pack">
            <aside className="photo">
                <img src={Tangier} alt="Tangier" width="250px" height="230px" />
            </aside>
            <aside className="content">
                <h6>Tangier</h6>
                <p>Discover our 2 tours in bride of the North.</p>
                <a href="/tangier-tours">
                    <button className="pack-detail-btn">VIEW ALL TOURS FOR TANGIER</button>
                </a>
            </aside>
        </article>

        <article className="pack">
            <aside className="photo">
                <img src={Fes} alt="Fes" width="250px" height="230px" />
            </aside>
            <aside className="content">
                <h6>Fes</h6>
                <p>Discover our 6 tours in the cultural and spiritual capital.</p>
                <a href="/fes-tours">
                    <button className="pack-detail-btn">VIEW ALL TOURS FOR FES</button>
                </a>
            </aside>
        </article>

        <article className="pack">
            <aside className="photo">
                <img src={Casablanca} alt="Casablanca" width="250px" height="230px" />
            </aside>
            <aside className="content">
                <h6>Casablanca</h6>
                <p>Discover our 7 tours in the industrial capital.</p>
                <a href="/casablanca-tours">
                    <button className="pack-detail-btn">VIEW ALL TOURS FOR CASABLANCA</button>
                </a>
            </aside>
        </article>
    </section>
  )
}

export default Packs