import React from 'react'

import Sqr from '../../../src/assets/linkedin_qr.png'

import '../credits/Credits.css'

const Credits = () => {
  return (
    <div className="credits">
        <section className="first-contributor">
            <h6>Front-end developpement</h6>
            <p>Website developped by Sami Kissai</p>
            <p>Greetings I am Kissai Sami. I'm a full stack web developper ready to develop optimal solutions to satisfy your needs.</p>
            <p>Scan the code to display my LinkedIn profile</p>
            <img src={Sqr} alt="Sami's linkedIn qr code" width="300px"/>
            <ul>
                <li>Email: samikissai.pro@gmail.com</li>
                <li>Phone number: +212664037202</li>
                <li><a href="https://github.com/k1ssa1" target="_blank" className="links-devs">follow me on github</a></li>
            </ul>
        </section>

        <section className="second-contributor">
            <h6>Logo and branding</h6>
            <p>Logo designed by Anas Nebdaoui.</p>
            <ul>
                <li>Phone number: +212708071759</li>
                <li><a href="https://tedora.info/" target="_blank" className="links-devs">Check my website</a></li>
            </ul>
        </section>
    </div>
  )
}

export default Credits