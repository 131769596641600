import React from 'react'
import {FaLaptopHouse} from "react-icons/fa";

import '../../components/presentation/Presentation.css'

const Presentation = () => {
  return (
    <article className="presentation">
        <section className="presentation-title">
            <FaLaptopHouse color="#D83C11" size="5rem"/>
            <h3>FIND OUT MORE <br/><span>ABOUT US.</span></h3>
        </section>
        <section className="morocco">
            <h6>WHY MOROCCO ?</h6>
            <p>North Africa is home to the kingdom of Morocco. It looks out over the Mediterranean Sea to the north and the Atlantic Ocean to the west. Morocco is a popular travel destination all year round because of its variety of weather. Additionally, it serves as a crossroads for several cultures, particularly the berber, moorish, and Jewish traditions, which all had an impact on Moroccan identity.</p>
            <p>During your trip in the kingdom of Morocco you’ll witness Moroccan hospitality and generosity, savour the exotic spices and flavours of the moroccan cuisine and experience the moroccan distinctive traditions.</p>
        </section>
        <section className="wandercasablanca">
            <h6>WHY CHOOSE US?</h6>
            <p>‘WANDERCASABLANCA’ is a moroccan travel agency. Since our origin in 2013, our main goal was to assure that our customers benefit from the most authentic moroccan experience. </p>
            <p>Through our innovative planned event packages and other basic travel services, we have been transporting outstanding experiences for a very long time. We inspire our clients to continue living rich lives filled with unforgettable travel experiences.</p>
            <p>‘‘WANDERCASABLANCA’’ is effectively connected with multiple complementary services such as acommodation service providers and indoor/outdoor activity providers...</p>
            <p>With the help of our site “www.wandercasablanca.com” we hope to deliver necessary information about our flexible packages and tours for our clients.</p>
        </section>
    </article>
  )
}

export default Presentation