import React from 'react'

import '../../components/Contact/Contact.css'

import {FaCommentDots,FaEnvelope,FaMapMarkerAlt,FaPhoneAlt,FaTripadvisor,FaInstagram} from "react-icons/fa";

const Contact = () => {
  return (
    <section className="contact" id="contact">
        <div className="contact-title">
            <FaCommentDots color="#F5F5F5" size="5rem"/>
            <h3>GET IN CONTACT <br></br> WITH US</h3>
        </div>
        <article className="contact-info">
            <aside className="info-left">
                <h5>CONTACT INFO</h5>
                <div className="location">
                    <FaMapMarkerAlt color="#F5F5F5" size="1.1rem"/>
                    <small>219, Boulevard Mohamed Zerktouni, Maârif Casablanca</small>
                </div>
                <div className="mail">
                    <FaEnvelope color="#F5F5F5" size="1.1rem"/>
                    <small>wandercasablanca@gmail.com</small>
                </div>
                <div className="phone">
                    <FaPhoneAlt color="#F5F5F5" size="1.1rem"/>
                    <small>+212 648- 179094</small>
                </div>
            </aside>
            <aside className="info-right">
                <h5>OR FIND US ON SOCIAL MEDIA</h5>
                <section className="social-credentials">
                    <a href="https://www.tripadvisor.com/Attraction_Review-g293732-d25149889-Reviews-Wander_Casablanca-Casablanca_Casablanca_Settat.html" className="social-links">
                        <FaTripadvisor color="#F5F5F5" size="5rem"/>
                    </a>
                    <a href="https://www.instagram.com/wander_casablanca/" className="social-links">
                        <FaInstagram color="#F5F5F5" size="5rem"/>   
                    </a>
                </section>
            </aside>
        </article>
        
    </section>
  )
}

export default Contact