import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";

import '../Casablanca-private-tour/CPrivate.css'

const CPrivate = () => {
  return (
    <div className="cprivate-small-tour">
    <Header/>
    <section className="cprivate-small-tour-heading">
        <h3>PRIVATE CASABLANCA TOUR</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>7 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Stroll around the colorful souks</li>
                <li>Spend the night under the starry sky of the Sahara desert</li>
                <li>Wander the narrow streets of Fes</li>
                <li>Enjoy the panoramic view by passing through the majestic high Atlas mountains</li>
                <li>Take the 1001 kasbahs road.</li>
                <li>Admire the impressive Ait Ben Haddou kasbah</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>If this is your first visit to Morocco and you want to see two imperial cities as well as experience the Sahara desert, then a private Casablanca tour is ideal for you. This journey begins in the red city of Marrakech. We will see the most well-known attractions, such as the Koutoubia mosque, the Saadian tombs, and many more sites. Then we depart for the Sahara desert, passing through the great high Atlas mountain chain before arriving in cities such as Ouarzazate, Dades Valley, Erfoud, and finally Merzouga in the desert. Then we head north, passing through cities like Ifrane, also called the Moroccan Switzerland before arriving in Fes, Morocco's intellectual and spiritual capital.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Arrival in Marrakech</p>
                <p className="day-description">When you arrive at Casablanca's airport, the driver will be waiting for you. You will be transported to your hotel in Marrakech to check in. You will also have time to relax and visit the medina and the souks. You will spend your night in a traditional riad.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Marrakech sightseeing</p>
                <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Marrakech-High Atlas Mountains-Dades Gorges</p>
                <p className="day-description">We will pick you up from your hotel in Marrakech and take you to the high Atlas mountains. The scenic drive will allow you to see many Kasbahs. Then we'll go through Ouarzazate, the Rose Valley, and the Skoura region, which has a large palmeraie. We'll finally arrive in Dades Valley, where you'll spend the night.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Dades Valley-Todra Gorge-Jorf-Erfoud-Merzouga-Sahara desert</p>
                <p className="day-description">In the morning, we return through the valley to see the incredible rock formations and the Ait Yul kasbah. We leave the Dades valley and travel to Tinghir before heading to the Todra gorges. We continue on to Erfoud and Rissani for lunch before departing for Merzouga after a short walk through the Gorge. Prepare for an unforgettable 1-hour guided camel trek that will transport you to ancient times in the afternoon. You'll travel through the Sahara desert on a dromedary before arriving at your overnight camp and sleeping under the stars in a tent.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 5: Sahara desert-Rissani-Ziz Valley-Azrou-Ifrane-Fes</p>
                <p className="day-description">We drive north through Tafilelt, the world's largest palm grove, after watching the sunrise on the sand dunes and eating breakfast. This leads to the Ziz Valley, where a river from the High Atlas flows down to water the palm trees. We can pause here to take in the view from the valley's highest point. We then proceed to Midelt, where we will stop for lunch. Then we continue on to Azrou's cedar forests to see the wildlife. The following destination is Ifrane, an architecturally European city. Finally, we arrive in Fes, where we will check in and spend the night.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 6: Fes sightseeing-Casabalanca</p>
                <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city. In the afternoon, we will depart for Casablanca. Once we reach Casablanca we will help you check into you accommodation.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 7: Departure from Casablanca</p>
                <p className="day-description">Today, your driver will take you to Casablanca's airport to catch your flight. And the tour will come to an end here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>6 nights accommodation in selected accommodations with breakfast</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>2 dinners</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Professional english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Guided tour in the city of Fes and Marrakech</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Tickets fees to the sites</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default CPrivate