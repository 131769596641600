import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes} from "react-icons/fa";


import '../Rabat-fes/RFes.css'

const RFes = () => {
  return (
    <div className="rabat-small-tour">
    <Header/>
    <section className="rabat-small-tour-heading">
        <h3>FES TO IMPERIAL CITIES TOUR</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>14 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Fes</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Fes</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Walk around the walls of the old medinas in the imperial cities.</li>
                <li>Visit a nomad family.</li>
                <li>Explore the charming city of chefchaouen.</li>
                <li>Embark on an escape visit to the sandy beaches of Essaouira.</li>
                <li>Travel on camel-back in the Sahara desert.</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>In this two-week travel package, you will have the opportunity to visit Morocco's imperial cities. We will travel from Fes to Meknes. Then we head south to witness the splendor of the sandy Sahara desert. Enjoy outdoor fun activities and meet a nomad family before departing for Ait ben Haddou, an iconic kasbah, then Ouarzazate before arriving in Marrakech. After that, we'll visit Essaouira and Casablanca, as well as the blue city of Chefchaouen, before returning to Fes.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Arrival in Fes</p>
                <p className="day-description">When you arrive at the airport, the driver will be waiting for you. You will be transported to your hotel to check in. You will also have time to relax and visit the medina and the souks.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Fes sightseeing</p>
                <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Meknes-Volubilis</p>
                <p className="day-description">We're off to Volubilis today. This site's archaeological relics bear witness to several civilizations. From prehistory to the Islamic period, all ten centuries of occupation are covered. The site has produced a large amount of artistic material, such as mosaics, marble, and hundreds of other items. Then we'll go to Meknes, one of Morocco's four imperial cities. The Almoravids established a city in the 11th century. The Bou Inania Madrasa, the Moulay Isma'il palace's gateway, and the Bab Mansour are all worth seeing. Finally, we will return to Fes.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Fes-Ifrane-Azrou-Midelt-Ziz Valley-Merzouga</p>
                <p className="day-description">Travel south, passing through Ifrane, Morocco's Switzerland. We'll stop by to photograph the city's European architecture. Then we'll go to Azrou to see the wildlife of the cedar forest, particularly the monkeys. We'll then proceed to Midelt for lunch. We'll pass through Errachia, the Ziz valley and gorges, Aoufous, and Erfoud on our way to the magnificent Merzouga dunes. We spend the night here.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 5: Merzouga-Hassi Labied-Khamlia-Nomad family-Fossil site-Tour of the dunes-Sahara desert</p>
                <p className="day-description">Following breakfast, you will embark on a 4x4 adventure through the Sahara desert, stopping to see volcanic rocks, an authentic nomad family, and the Khamlia village with Gnawa music entertainment. Then we'll go to Lake Sarji, which forms in the spring and attracts a wide range of wildlife. We'll then have lunch in Mezroouga or Hassi Labied. Following lunch, you'll be able to see the canal irrigation system and walk through the oasis at the dunes' foot. In the afternoon, get ready for an unforgettable 1-hour guided camel trek that will transport you to ancient times. You will ride a dromedary through the Sahara desert before arriving at your overnight camp and spending the night in a tent.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 6: Sahara Desert-Erfoud-Todra gorge-Dades Valley</p>
                <p className="day-description">Get up early to watch the sunrise from the dunes' crest. We will travel to Tineghir via Erfoud and Tinejdad after breakfast. We'll stop for lunch later before heading to the Todra gorges, where you can hike through the geological formations. Then we'll take you to your hotel in the Dades Gorges.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 7: Dades Valley-Ait Ben Haddou Kasbah-Marrakech</p>
                <p className="day-description">On this day, we return to Marrakech via the Rose Valley and Ouarzazate, where we visit the Taourirte Kasbah and the filming locations for Gladiator, Game of Thrones, and Prison Break. Finally, we will travel to Marrakech, the red city.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 8: Marrakech sightseeing</p>
                <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 9: Day-trip to Essaouira</p>
                <p className="day-description">Escape Marrakech for a quick one-day trip to Essaouira. We'll stop on the way to Essaouira to see the goats on top of the iconic Argan trees. We will also have the opportunity to visit a local feminine argan cooperative and observe the argan oil production process. Later, we will arrive in Essaouira. You can go sightseeing in this small but charming city. We'll go to the ports to see the day's catch and eat a delicious sea food lunch at a local restaurant. We'll return to Marrakech in the afternoon so you can unwind at your hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 10: Marrakech</p>
                <p className="day-description">Your 2 weeks trip from Fes continues in the city of Marrkaech. On this day, we recommand you to visit the modern city. Visit Gueliz for its Malls and shopping center. At night you can have an optional dinner with a show in a restaurant.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 11: Marrakech-Casablanca-Rabat</p>
                <p className="day-description">Today, we'll take the highway to Casablanca. After a three-hour drive, we will arrive in Casablanca to visit the Hassan 2 mosque. One of the largest mosques in the world. Then we'll continue on to Rabat. When we arrive, you will check into your hotel, and we will then visit Rabat, Morocco's capital city. We will see the Hassan tour, the Oudaya kasbah, the Mohamed 5 mausoleum, and other sights.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 12: Rabat-Chefchaouen</p>
                <p className="day-description">We'll travel to the lovely blue city of Chefchaouen. Explore the photogenic city's narrow streets. Unwind in the square...</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 13: Chefchaouen-Fes</p>
                <p className="day-description">Today, you will have some free time after breakfast to explore Chechaouen on your own before heading to Fes to check into your accommodation.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 14: Departure from Fes</p>
                <p>Today, your driver will take you to Fes's airport to catch your flight. And the tour will come to an end here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Selected accomodations: 13 overnights with breakfast</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Official guided tour of Marrakech and Casablanca</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>3 dinners</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Camel ride in the Sahara desert with camel guide</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Monuments fees</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default RFes