import React from 'react'

import '../../Marrakech/Marrakech-small-tour/MSmallTour.css'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes} from "react-icons/fa";

const MSmallTour = () => {
  return (
    <div className="Marrakech-small-tour">
        <Header/>
        <section className="Marrakech-small-tour-heading">
            <h3>MARRAKECH SMALL TOUR</h3>
        </section>
        <section className="service-info">
            <aside className="service-info-sections">
            <FaClock color="#F24E1E" size="2rem"/>
            <p>7 days</p>
            </aside>
            <aside className="service-info-sections">
            <FaCalendarCheck color="#F24E1E" size="2rem"/>
            <p>Availabale all year</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneArrival color="#F24E1E" size="2rem"/>
            <p>Marrakech</p>
            </aside>
            <aside className="service-info-sections">
            <FaPlaneDeparture color="#F24E1E" size="2rem"/>
            <p>Casablanca</p>
            </aside>
        </section>
        <section className="hightlights">
            <section className="hightlight-heading">
                <FaLightbulb color="#F24E1E" size="2rem"/>
                <h6>Highlights</h6>
            </section>
            <section className="hightlight-list">
                <ul>
                    <li>Walk around the old medina for a unique shopping experience.</li>
                    <li>Visit the countryside to experience the moroccan culture and tradtions.</li>
                    <li>Give the Bahia palace a visit and witness the majestic architecture.</li>
                    <li>Embark on an escape visit to the sandy beaches of Essaouira.</li>
                    <li>Relax in a cafe with a view.</li>
                </ul>
            </section>
        </section>
        <section className="description">
            <section className="description-heading">
                <FaBookOpen color="#F24E1E" size="2rem"/>
                <h6>Description</h6>
            </section>
            <section className="description-content">
                <p>Morocco Small Tour is a 7-day tour designed for those with limited time. It is a tour that is intended to not overburden our clients by visiting multiple cities in a short period of time. This 7-day tour will take you to two of Morocco's most well-known cities. You will begin in Marrakech and work your way to Casablanca. In addition, you will receive two optional day trips.</p>
                <p>In Marrakech, we will begin by exploring the bustling, colorful markets. Following that, we have two optional day trips. The first is a trip to Essaouira to relax on its beautiful sandy beaches. The second optional trip is to Ourika Valley, where you can learn about Moroccan traditions and participate in outdoor activities like camel rides. After enjoying the delights of Marrakech, we will spend the last two days in Morocco's industrial capital, Casablanca fro more sightseeing.</p>
            </section>
        </section>
        <section className="itinary">
            <section className="itinary-heading">
                <FaRoad color="#F24E1E" size="2rem"/>
                <h6>Itinary</h6>
            </section>
            <section className="itinary-content">
                <aside className="day">
                    <p className="day-title">Day 1: Arrival in Marrakech</p>
                    <p className="day-description">When you arrive at the airport, the driver will be waiting for you. You will be transported to your hotel to check in. You will also have time to relax and visit Jammaa El Fna Square.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 2: Marrakech sightseeing</p>
                    <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, and so on. In the afternoon, you have the option of taking a camel ride in the Palmeraie.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 3: Optional trip to Essaouira</p>
                    <p className="day-description">This day, you have the option of either having a free day in Marrakech or escaping Marrakech for a quick one-day trip to Essaouira. We'll stop on the way to Essaouira to see the goats on top of the iconic Argan trees. We will also have the opportunity to visit a local feminine argan cooperative and observe the argan oil production process. Later, we will arrive in Essaouira. You can go sightseeing in this small but charming city. We'll go to the ports to see the day's catch and eat a delicious sea food lunch at a local restaurant. We'll return to Marrakech in the afternoon so you can unwind at your hotel.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 4: Optional trip to Ourika</p>
                    <p className="day-description">You can choose to have a free day on this day, as we recommend Gueliz as a modern part of the city. Alternatively, you can take an optional trip to the Ourika valley to see the beautiful waterfalls and learn about Moroccan berber traditions with a local family. You can also choose to have dinner and watch the fantasia show at Chez Ali in the afternoon.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 5: Marrakech to Casablanca</p>
                    <p className="day-description">We'll leave in the morning for Casablanca. After 3 hours, you will arrive at your hotel and check in. You will have free time in the afternoon to explore the city. Alternatively, you can take an optional trip to the Ourika valley to see the beautiful waterfalls and learn about Moroccan berber traditions with a local family. You can also choose to have dinner and watch the fantasia show at Chez Ali in the afternoon.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 6: Casablanca sightseeing</p>
                    <p className="day-description">After meeting your English-speaking guide, you will tour the city's most well-known attractions, including the Habous district, the royal palace, the Ain Diab corniche, and the majestic Hassan 2 mosque.</p>
                </aside>
                <aside className="day">
                    <p className="day-title">Day 7: Departure from Casablanca</p>
                    <p className="day-description">Today, your driver will take you to Casablanca's airport to catch your flight. And the tour will come to an end here.</p>
                </aside>
            </section>

        </section>
        <section className="tour-detail">
                <section className="tour-detail-heading">
                    <FaInfoCircle color="#F24E1E" size="2rem"/>
                    <h6>Includes/Excludes</h6>
                </section>
                <section className="tour-heading-content">
                    <ul>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>3 starts hotels or riads: 6 overnights with breakfast</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Official guided tour of Marrakech and Casablanca</p>
                        </li>
                        <li>
                            <FaCheck color="#F24E1E" size="1rem" />
                            <p>Fuels and tolls</p>
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Lunch and dinner</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Monuments fees</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Travel insurance</p>
                        </li>
                        <li>
                            <FaTimes color="#F24E1E" size="1rem" />
                            <p>Drinks</p>
                        </li>
                    </ul>
                    
                </section>
            </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default MSmallTour