import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes} from "react-icons/fa";

const RRoyalTour = () => {
  return (
    <div className="rabat-small-tour">
    <Header/>
    <section className="rabat-small-tour-heading">
        <h3>ROYAL TOUR OF MOROCCO</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>11 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Marrakech</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Marrakech</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Walk around the walls of the old medina.</li>
                <li>Visit a berber family.</li>
                <li>Watch the funny tree-climibing goats.</li>
                <li>Visit the most known landmarks.</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>This is one of the most popular tours we offer. During this 11-day tour, you will be guaranteed to visit four imperial cities of Morocco, as well as other beautiful locations. We begin our journey in Marrakech, the red city, before departing for a day trip to Essaouira. When we return to Marrakech, we will visit the Ourika Valley. After that, we'll head to Casablanca to see the impressive Hassan 2 mosque before heading to Rabat for two nights. Then we travel to other imperial cities, Meknes and Fes, before returning to Marrakech via the blue city of Chefchaouen.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Arrival in Marrakech</p>
                <p className="day-description">When you arrive at the airport, the driver will be waiting for you. You will be transported to your hotel to check in. You will also have time to relax and visit Jammaa El Fna Square.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Marrakech sightseeing</p>
                <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Day-trip to Essaouira</p>
                <p className="day-description">Escape Marrakech for a quick one-day trip to Essaouira. We'll stop on the way to Essaouira to see the goats on top of the iconic Argan trees. We will also have the opportunity to visit a local feminine argan cooperative and observe the argan oil production process. Later, we will arrive in Essaouira. You can go sightseeing in this small but charming city. We'll go to the ports to see the day's catch and eat a delicious sea food lunch at a local restaurant. We'll return to Marrakech in the afternoon so you can unwind at your hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Day-trip to Ourika</p>
                <p className="day-description">In the morning, we will go on a trip to the Ourika valley to see the beautiful waterfalls and learn about Moroccan berber traditions with a local family. You can also choose to have dinner and watch the fantasia show at Chez Ali in the afternoon.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 5: Marrakech-Casablanca-Rabat</p>
                <p className="day-description">Today, we'll take the highway to Casablanca. After a three-hour drive, we will arrive in Casablanca to visit the Hassan 2 mosque. One of the largest mosques in the world. Then we'll continue on to Rabat. When we arrive, you will check into your hotel, and you will have free time to then visit Rabat on your own.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 6: Rabat sightseeing</p>
                <p className="day-description">It is time to visit Morocoo's capital in the morning, after breakfast. The Hassan tour, the Oudaya Kasbah, the Mohamed 5 mausoleum, the archeological fortress Chellah, and other sites will be visited.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 7: Rabat-Meknes-Fes</p>
                <p className="day-description">Today we travel to Fes via Meknes. Meknes is a city one of Morocco's four imperial cities. In the 11th century, the Almoravids founded the city. The Bou Inania Madrasa, the gateway to the Moulay Isma'il palace, and the Bab Mansour are all worth seeing. Finally, the spiritual and intellectual capital will be attained. Fes. You will check into your hotel. In the afternoon, the old city and souks are the best places to visit.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 8: Fes sightseeing</p>
                <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 9: Day-trip to Chefchaoeun</p>
                <p className="day-description">We'll travel to the lovely blue city of Chefchaouen. Explore the photogenic city's narrow streets. Unwind in the square. In the afternoon, we will drive back to Fes so you can spend the night in your hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 10: Fes-Beni Mella-Marrakech</p>
                <p className="day-description">Today, we travel to Marrakech via Ifrane and Beni Mella, two of the kingdom's most important agricultural cities. We'll eat lunch here before continuing on to Marrakech.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 11: Departure from Marrakech</p>
                <p>Today, your driver will take you to Marrakech's airport to catch your flight. And the tour will come to an end here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>10 overnights with breakfast</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Official guided tour of Marrakech and Fes</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch, dinners and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Monuments fees</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default RRoyalTour