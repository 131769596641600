import React from 'react'

import Header from '../../../components/Header/Header'
import Contact from '../../../components/Contact/Contact'
import Footer from '../../../components/Footer/Footer'

import '../../Services/Fes/Fes.css'

import fchaouen from '../../../assets/fchaouen.jpg'
import fmosque from '../../../assets/fmosque.jpg'
import fkech from '../../../assets/fkech.jpg'
import fdesert from '../../../assets/fdesert.jpg'
import essaouira from '../../../assets/essaouira.jpg'
import casablanca from '../../../assets/casablanca.jpg'

const Fes = () => {
  return (
    <div className="fes">
        <Header/>
        <section className="fes-heading">
            <h3>FES TOURS</h3>
        </section>
        <section className="fes-packs">
            <section className="fes-first-line">
                <article className="fes-pack">
                    <aside className="fes-photo">
                        <img src={casablanca} alt="casablanca" width="300px" height="260px" />
                    </aside>
                    <aside className="fes-content">
                        <h6>Desert trip from Fes to Casablnca trip</h6>
                        <p>A one-week journey from Fes to Casablanca through the Sahara desert.</p>
                        <a href="/fes-casablanca-tour">
                            <button className="fes-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>

                <article className="fes-pack">
                    <aside className="fes-photo">
                        <img src={essaouira} alt="essaouira" width="300px" height="260px" />
                    </aside>
                    <aside className="fes-content">
                        <h6>15 days tour from Fes to imperial cities</h6>
                        <p>Travel around Morocco for 15 days, beginning in Fes.</p>
                        <a href="/fes-to-imperial-cities-tour">
                            <button className="fes-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
                
            </section>
            <section className="fes-first-line">
                <article className="fes-pack">
                    <aside className="fes-photo">
                        <img src={fdesert} alt="desert" width="300px" height="260px" />
                    </aside>
                    <aside className="fes-content">
                        <h6>Fes to the desert for two days</h6>
                        <p>Quick escape trip starting from Fes to the south for 2 days.</p>
                        <a href="/fes-two-days-desert-tour">
                            <button className="fes-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>

                <article className="fes-pack">
                    <aside className="fes-photo">
                        <img src={fkech} alt="marrakech" width="300px" height="260px" />
                    </aside>
                    <aside className="fes-content">
                        <h6>Fes, Sahara desert and Marrakech in 4 days</h6>
                        <p>A trip to the sahara desert and Marrakech starting from Fes.</p>
                        <a href="fes-sahara-marrakech-in-4-days-tour">
                            <button className="fes-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
                
            </section>

            <section className="fes-first-line">
                <article className="fes-pack">
                    <aside className="fes-photo">
                        <img src={fmosque} alt="mosque" width="300px" height="260px" />
                    </aside>
                    <aside className="fes-content">
                        <h6>Fes to Marrakech desert tour</h6>
                        <p>A three-day, two-night journey from Fez to Marrakech through the Sahara Desert.</p>
                        <a href="/fes-to-marrakech-desert-tour">
                            <button className="fes-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>

                <article className="fes-pack">
                    <aside className="fes-photo">
                        <img src={fchaouen} alt="chefchaouen" width="300px" height="260px" />
                    </aside>
                    <aside className="fes-content">
                        <h6>Fes to Chefchaouen 4-day tour</h6>
                        <p>On a four-day trip tour, discover two of the kingdom's most enchanting cities..</p>
                        <a href="/fes-chefchaouen-tour">
                            <button className="fes-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
                
            </section>

            
        </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Fes