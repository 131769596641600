import React from 'react'

import Header from '../../../components/Header/Header'
import Contact from '../../../components/Contact/Contact'
import Footer from '../../../components/Footer/Footer'

import '../Tangier/Tangier.css'

import tdesert from '../../../assets/tdesert.jpg'
import cap from '../../../assets/cap.jpg'


const Tangier = () => {
  return (
    <div className="Tangier">
        <Header/>
        <section className="Tangier-heading">
            <h3>TANGIER TOURS</h3>
        </section>
        <section className="tangier-packs">
            <section className="tangier-first-line">
                    <article className="tangier-pack">
                        <aside className="tangier-photo">
                            <img src={tdesert} alt="sahara" width="290px" height="260px" />
                        </aside>
                        <aside className="tangier-content">
                            <h6>Morocco private tour from north to south</h6>
                            <p>Explore Morocco from Tangier to the kingdom's south.</p>
                            <a href="/tangier-north-south-tour">
                                <button className="tangier-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                            </a>
                        </aside>
                    </article>


                    <article className="tangier-pack">
                        <aside className="tangier-photo">
                            <img src={cap} alt="Tangier beach" width="290px" height="260px" />
                        </aside>
                        <aside className="tangier-content">
                            <h6>10-day trip from Tangier</h6>
                            <p>Tangier to other imperial cities and the Sahara Desert in ten days..</p>
                            <a href="/tangier-ten-day-tour">
                                <button className="tangier-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                            </a>
                        </aside>
                    </article>
                </section>

                
        </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Tangier