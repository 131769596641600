import React from 'react'

import Header from '../components/Header/Header'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'

import '../../src/utils/Notfound.css'

import { FaRegTired } from "react-icons/fa";


const NotFound = () => {
  return (
    <div className="not-found">
        <Header/>
        <section className="notfound-message">
        <FaRegTired size="10rem" color="#808080"/>
        <p>Error 404: page could not be found</p>
        <a href="/">go back to home page</a>
        </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default NotFound