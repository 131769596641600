import React from 'react'

import '../Rabat/Rabat.css'

import Header from '../../../components/Header/Header'
import Contact from '../../../components/Contact/Contact'
import Footer from '../../../components/Footer/Footer'

import Fez from '../../../assets/fez.jpg'
import chefchaouen from '../../../assets/chefchaouen.jpg'
import casablanca from '../../../assets/casablanca.jpg'
import fdesert from '../../../assets/fdesert.jpg'
import marrakech from '../../../assets/Marrakech.jpg'

const Rabat = () => {
  return (
    <div className="rabat">
        <Header/>
        <section className="rabat-heading">
            <h3>RABAT TOURS</h3>
        </section>
        <section className="rabat-packs">
        <section className="rabat-first-line">
                <article className="rabat-pack">
                    <aside className="rabat-photo">
                        <img src={Fez} alt="Fez" width="290px" height="260px" />
                    </aside>
                    <aside className="rabat-content">
                        <h6>Fez to imperial cities</h6>
                        <p>A two weeks trip starting from Fes to imperial cities</p>
                        <a href="/fes-to-imperial-cities-tour">
                            <button className="rabat-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>

                <article className="rabat-pack">
                    <aside className="rabat-photo">
                        <img src={chefchaouen} alt="chefchaouen" width="290px" height="260px" />
                    </aside>
                    <aside className="rabat-content">
                        <h6>Royal tour of Morocco</h6>
                        <p>Discover Morocco on a 11 days trip.</p>
                        <a href="/rabat-royal-tour">
                            <button className="rabat-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
            </section>

            <section className="rabat-first-line">
                <article className="rabat-pack">
                    <aside className="rabat-photo">
                        <img src={casablanca} alt="casablanca" width="290px" height="260px" />
                    </aside>
                    <aside className="rabat-content">
                        <h6>Majestic Morocco tour from Casablanca</h6>
                        <p>8 days trip around the kingdom starting from Casablanca.</p>
                        <a href="/rabat-majestic-tour">
                            <button className="rabat-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>

                <article className="rabat-pack">
                    <aside className="rabat-photo">
                        <img src={fdesert} alt="desert" width="290px" height="260px" />
                    </aside>
                    <aside className="rabat-content">
                        <h6>Moroccan panorama tour</h6>
                        <p>A 14 days and 13 nights trip across the country.</p>
                        <a href="/rabat-panorama-tour">
                            <button className="rabat-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
            </section>

            <section className="rabat-first-line">
                <article className="rabat-pack">
                    <aside className="rabat-photo">
                        <img src={marrakech} alt="marrakech" width="290px" height="260px" />
                    </aside>
                    <aside className="rabat-content">
                        <h6>Morocco tour starting from Marrakech to imperial cities</h6>
                        <p>A ten days trip from Marrakech.</p>
                        <a href="/marrakech-grand-tour">
                            <button className="rabat-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
            </section>
           
            
        </section>
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Rabat