import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes} from "react-icons/fa";

const RPanorama = () => {
  return (
    <div className="rabat-small-tour">
    <Header/>
    <section className="rabat-small-tour-heading">
        <h3>MOROCCO PANORAMA TOUR</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>14 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Visit Morocco's imperial cities.</li>
                <li>Visit the beautiful city of Essaouira on a daytrip.</li>
                <li>Go on a safari to the Sahara desert in Mezrouga and visit a nomad family.</li>
                <li>Take the 1001 kasbah road.</li>
                <li>Witness the splendor of the high Atlas mountains.</li>
                <li>Walk inside the blue city, Chefchaouen.</li>
                <li>Eperience the Sahara desert on camelback with a camel guide.</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>Morocco panorama tour is an 14-day and 13 ngiths travel package that allows you to visit all four imperial cities of Morocco's kingdom. We'll start in Rabat with some sightseeing before heading to Meknes, another imperial city known for its beautiful madrassas. Following that, we will visit Fes, the spiritual and intellectual capital of Morocco. After that we will head to the south to see the magnificent Merzouga and its sand dunes. Finally, before returning to Casablanca, we will visit the beautiful city of Marrakech with an optional visit to Essaouira.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Arrival in Casablanca</p>
                <p className="day-description">When you arrive at the airport, the driver will be waiting for you. You will be transported to your hotel to check in. You will also have time to relax and discover the city on your own.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Casablanca sightseeing</p>
                <p className="day-description">After meeting your English-speaking guide, you will tour the city's most well-known attractions, including the Habous district, the royal palace, the Ain Diab corniche, and the majestic Hassan 2 mosque.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Rabat-Volubilis-Meknes-Fes</p>
                <p className="day-description">Today we will visit Meknes, one of Morocco's four imperial cities. The Almoravids founded the city in the 11th century. The Bou Inania Madrasa, the Moulay Isma'il palace's gateway, and the Bab Mansour are all worth seeing. Then we'll head to Fes to check into your hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Fes sightseeing</p>
                <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 5: Day trip to Chefchaouen</p>
                <p className="day-description">We'll travel to the lovely blue city of Chefchaouen. Explore the photogenic city's narrow streets. Unwind in the square. You can also visit a farm for local products such as goat cheese. In the afternoon, we will take you back to Fes.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 6: Fes-Ifrane-Azrou-Midelt-Merzouga-Sahara Desert</p>
                <p className="day-description">Travel south, passing through Ifrane, Morocco's Switzerland. We'll stop by to photograph the city's European architecture. Then we'll go to Azrou to see the wildlife of the cedar forest, particularly the monkeys. We'll then proceed to Midelt for lunch. We'll pass through Errachia, the Ziz valley and gorges, Aoufous, and Erfoud on our way to the magnificent Merzouga dunes. There you will spend the night in a tent with live Saharan music.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 7: Sahara desert-Black desert-Khamlia village</p>
                <p className="day-description">Get up early to watch the sunrise from the dunes' top. Once you'll enjoy your morning breakfast, we will take you on a 4x4 tour of the dunes, allowing you to visit the black volcanicrocks. You will also get the chance to visit a nomad family and the khamlia village for its Gnawa entertainment. After that we head to a lake near Merzouga called 'Dayte Sarji' that forms during springtime and attracts flamingos and wildlife. We will have lunch in either Merzouga or Hassi Labied. After lunch, you will see the canal irigation system and have a walk on the oasis located near the dunes. Finally, we will take you back to your tent in the Sahara desert to rest for the night.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 8: Sahara Desert-Erfoud-Todra gorge-Dades Valley</p>
                <p className="day-description">After a delicious breakfast, We will travel to Tineghir via Erfoud and Tinejdad after breakfast. We'll stop for lunch later before heading to the Todra gorges, where you can hike through the geological formations. Then we'll take you to see the panoramic view of the Dades Gorges. We continue our journey to Ouarzazate, passing by the Rose valley for its bio cosmetic products like rose based products. We will pass by Skoura home of 700,000 palm trees before arriving in Ouarzazate for the night.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 9: Ouarzazate-Ait Ben Haddou</p>
                <p className="day-description">Today we learn more about the imblematic Ait Ben Haddou and Ouarzazate. Here we will see the Taourirte Kasbah and filming studios where Gladiator, Game of Thrones, and Prison Break were filmed. Finally, we will return to Ouarzazate for the night.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 10: Ouarzazate-Marrakech</p>
                <p className="day-description">After having breakfast, we will leave for the red city Marrakech passing by the high Atlas mountains. We'lll drop you off in Marrakech and help you check into your accommodation. You will have free time to explore the city on your own.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 11: Marrakech sightseeing</p>
                <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 12: Day-trip to Essaouira</p>
                <p className="day-description">Escape Marrakech for a quick one-day trip to Essaouira. We'll stop on the way to Essaouira to see the goats on top of the iconic Argan trees. We will also have the opportunity to visit a local feminine argan cooperative and observe the argan oil production process. Later, we will arrive in Essaouira. You can go sightseeing in this small but charming city. We'll go to the ports to see the day's catch and eat a delicious sea food lunch at a local restaurant. We'll return to Marrakech in the afternoon so you can unwind at your hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 13: Marrakech free day</p>
                <p className="day-description">On this day, we recommand you to visit the modern city. Visit Gueliz for its Malls and shopping center. At night you can have an optional dinner with a show in the infamous chez Ali.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 14: Marrakech-Casablanca airport</p>
                <p className="day-description">Today, your driver will take you to Casablanca's airport to catch your flight. And the tour will come to an end here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>7 overnights in selected accomodations with breakfast</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Official guided tour of Marrakech and Fes</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch, dinners and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Monuments fees</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default RPanorama