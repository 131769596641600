import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


import NotFound from './utils/NotFound'

import Credits from '../src/views/credits/Credits'
import Terms from '../src/views/TermsConditions'
import Privacy from '../src/views/Privacy'


import Home from '../src/views/home/Home'
import Services from '../src/views/Services/Services'
import Aboutus from '../src/views/aboutus/Aboutus'
import Marrakech from '../src/views/Services/Marrakech/Marrakech'
import Tangier from '../src/views/Services/Tangier/Tangier'
import Fes from '../src/views/Services/Fes/Fes'
import Casablanca from '../src/views/Services/Casablanca/Casablanca'
import Rabat from '../src/views/Services/Rabat/Rabat'

import MSmallTour from '../src/views/Services/Marrakech/Marrakech-small-tour/MSmallTour'
import MGrandTour from '../src/views/Services/Marrakech/Marrakech-grand-tour/MGrandTour'
import MDesertTrip from '../src/views/Services/Marrakech/Marrakech-desert-tour/MDesertTrip'
import MSouth from '../src/views/Services/Marrakech/Marrakech-south/MSouth'
import MBestMorocco from './views/Services/Marrakech/Marrakech-best-morocco/MBestMorocco'
import MSahara from './views/Services/Marrakech/Marrakech-sahara/MSahara'
import MFesDesert from './views/Services/Marrakech/Marrakech-fes-desert/MFesDesert'
import TNorthSouth from './views/Services/Tangier/Tangier-north-south/TNorthSouth'
import TTendays from './views/Services/Tangier/Tangier-ten-days/TTenDays'
import RFes from './views/Services/Rabat/Rabat-fes/RFes'
import RRoyalTour from './views/Services/Rabat/Rabat-royal-tour/RRoyalTour'
import RMajestic from './views/Services/Rabat/Rabat-majestic-tour/RMajestic'
import RPanorama from './views/Services/Rabat/Rabat-panorama-tour/RPanorama'
import FChefchaouen from './views/Services/Fes/Fes-chefchaouen/FChefchaouen'
import FMarrakech from './views/Services/Fes/Fes-marrakech/FMarrakech'
import FDesertTour from './views/Services/Fes/Fes-desert/FDesert'
import FCasablanca from './views/Services/Fes/Fes-casablanca/FCasablanca'
import FSaharaMarrakech from './views/Services/Fes/Fes-sahara-marrakech/FSaharaMarrakech'
import CPrivate from './views/Services/Casablanca/Casablanca-private-tour/CPrivate'
import CGrandTour from './views/Services/Casablanca/Casablanca-grand-tour/CGrandTour'
import CImperial from './views/Services/Casablanca/Casablanca-imperial-cities/CImperial'
import CMoroccoHighlights from './views/Services/Casablanca/Casablanca-highlights/CMoroccoHighlights'
import CExtended from './views/Services/Casablanca/Casablanca-extended/CExtended'


function App() {
  return (
    <Router>
        <div className="App">
          <div className="content">
            <Switch>
              <Route exact path="/">
                  <Home/>
              </Route>
              <Route exact path="/aboutus">
                  <Aboutus/>
              </Route>
              <Route exact path="/services">
                  <Services/>
              </Route>
              <Route exact path="/marrakech-tours">
                  <Marrakech/>
              </Route>
              <Route exact path="/tangier-tours">
                  <Tangier/>
              </Route>
              <Route exact path="/fes-tours">
                  <Fes/>
              </Route>
              <Route exact path="/casablanca-tours">
                  <Casablanca/>
              </Route>
              <Route exact path="/rabat-tours">
                  <Rabat/>
              </Route>
              <Route exact path="/marrakech-small-tour">
                  <MSmallTour/>
              </Route>
              <Route exact path="/marrakech-grand-tour">
                  <MGrandTour/>
              </Route>
              <Route exact path="/marrakech-five-days-desert-trip">
                  <MDesertTrip/>
              </Route>
              <Route exact path="/marrakech-to-the-south-tour">
                  <MSouth/>
              </Route>
              <Route exact path="/marrakech-best-of-tour">
                  <MBestMorocco/>
              </Route>
              <Route exact path="/marrakech-sahara">
                  <MSahara/>
              </Route>
              <Route exact path="/marrakech-fes-desert">
                  <MFesDesert/>
              </Route>
              <Route exact path="/tangier-north-south-tour">
                  <TNorthSouth/>
              </Route>
              <Route exact path="/tangier-ten-day-tour">
                  <TTendays/>
              </Route>
              <Route exact path="/fes-to-imperial-cities-tour">
                  <RFes/>
              </Route>
              <Route exact path="/rabat-royal-tour">
                  <RRoyalTour/>
              </Route>
              <Route exact path="/rabat-majestic-tour">
                  <RMajestic/>
              </Route>
              <Route exact path="/rabat-panorama-tour">
                  <RPanorama/>
              </Route>
              <Route exact path="/fes-chefchaouen-tour">
                  <FChefchaouen/>
              </Route>
              <Route exact path="/fes-to-marrakech-desert-tour">
                  <FMarrakech/>
              </Route>
              <Route exact path="/fes-two-days-desert-tour">
                  <FDesertTour/>
              </Route>
              <Route exact path="/fes-casablanca-tour">
                  <FCasablanca/>
              </Route>
              <Route exact path="/fes-sahara-marrakech-in-4-days-tour">
                  <FSaharaMarrakech/>
              </Route>
              <Route exact path="/credits">
                  <Credits/>
              </Route>
              <Route exact path="/casablanca-private-tour">
                  <CPrivate/>
              </Route>
              <Route exact path="/casablanca-grand-tour">
                  <CGrandTour/>
              </Route>
              <Route exact path="/casablanca-imperial-cities-tour-package">
                  <CImperial/>
              </Route>
              <Route exact path="/casablanca-highlights-tour">
                  <CMoroccoHighlights/>
              </Route>
              <Route exact path="/casablanca-extended-tour">
                  <CExtended/>
              </Route>
              <Route exact path="/terms-and-conditions">
                  <Terms/>
              </Route>
              <Route exact path="/privacy-policies">
                  <Privacy/>
              </Route>
              <Route path="*">
                  <NotFound/>
              </Route>
            </Switch>
          </div>
        </div>
    </Router>
  );
}

export default App;
