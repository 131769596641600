import React from 'react'

import '../../components/Team/Team.css'

import {FaPhoneSquareAlt} from "react-icons/fa";

import Ahmed from '../../assets/ahmed.jpeg'
import Youssef from '../../assets/Youssef.jpeg'

const Team = () => {
  return (
    <section className="team">
        <section className="team-title">
            <h6>MEET OUR TEAM</h6>
            <p>Get in contact with our team’s qualified and experienced representatives.</p>
        </section>
        <section className="members">
            <aside className="ahmed">
                <img src={Ahmed} alt="Ahmed" width="300px" height="400px"/>
                <p className="members-name">Ahmed</p>
                <p className="job-title">Manager and coordinator</p>
                <div className="member-num">
                    <FaPhoneSquareAlt color="#310B0B" size="1.4rem" />
                    <p>+212 648-179094</p>
                </div>
            </aside>
            <aside className="youssef">
            <img src={Youssef} alt="Youssef" width="300px" height="400px"/>
            <p className="members-name">Youssef</p>
            <p className="job-title">Tour guide</p>
                <div className="member-num">
                    <FaPhoneSquareAlt color="#310B0B" size="1.4rem" />
                    <p>+212 658648294</p>
                </div>
            </aside>
        </section>
    </section>
  )
}

export default Team