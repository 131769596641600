import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";


const CExtended = () => {
  return (
    <div className="cprivate-small-tour">
    <Header/>
    <section className="cprivate-small-tour-heading">
        <h3>EXTENDED CASABLANCA PRIVATE TOUR PACKAGE </h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>10 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
        <aside className="service-info-sections">
        <FaUsers color="#F24E1E" size="2rem"/>
        <p>8 people max</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Stroll around the colorful souks in the imperial cities</li>
                <li>Enjoy the desert dunes and visit like a real nomad</li>
                <li>Visit the beautiful city of Chefchaouen</li>
                <li>Visit the fish market in Essaouira</li>
                <li>Visit the iconic Ait Ben Haddou Kasbah</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>Extended Casablanca private tour package is a 10-day trip that allows our cherished customers to visit all four imperial cities of the kingdom and enjoy the full Sahara experience. We'll begin in Casablanca, where you can see the Hassan 2 mosque. We then depart for Rabat, Morocco's capital and another imperial city. Then continue north, passing through Chefchaouen the blue city, Meknes, and Fes, two imperial and iconic cities. Admire the fine artisan works. Then we'll head south to Merzouga. See the incredible sand dunes of the Sahara desert. Our tour concludes in the red city of Marrakech after a day trip to the coastal city of Essaouira.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Casablanca-Rabat</p>
                <p className="day-description">When you arrive at the airport, the driver will be waiting for you. Visit the majestic Hassan 2 mosque which is considered one of the biggest mosques in the world. You will be transported to Rabat so you can check into your hotel. We'll visit the Hassan tour, visit the Oudaya kasbah, and see the Mohamed 5 mausoleum, among other sites.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Rabat-Chefchaouen</p>
                <p className="day-description">Right after having your breakfast, we will head to the lovely blue city of Chefchaouen. Explore the photogenic city's narrow streets. Unwind in the square and take in the moorish and spanish archiecture. Here, you will spend the night in your accommodation.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Chefchaouen-Volubilis-Meknes-Fes</p>
                <p className="day-description">Today we travel to Volubilis. The archaeological relics of this site testify to several civilizations. All ten centuries of occupation are covered, from prehistory to the Islamic period. The site has yielded a significant amount of artistic material, including as mosaics, marble, and hundreds of other items. <br/> Following that, we'll travel to Meknes, one of Morocco's four imperial cities. In the 11th century, the Almoravids founded the city. The Bou Inania Madrasa, the gateway to the Moulay Isma'il palace, and the Bab Mansour are all worth seeing. <br/> Finally, the spiritual and intellectual capital will be attained. Fes. You will check into your hotel. In the afternoon, the old city and souks are the best places to visit.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Fes sightseeing</p>
                <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 5: Fes-Ifrane-Azrou-Midelt-Ziz Valley-Merzouga</p>
                <p className="day-description">Travel south, passing through Ifrane, Morocco's Switzerland. We'll stop by to photograph the city's European architecture. Then we'll go to Azrou to see the wildlife of the cedar forest, particularly the monkeys. We'll then proceed to Midelt for lunch. We'll pass through Errachia, the Ziz valley and gorges, Aoufous, and Erfoud on our way to the magnificent Merzouga dunes. We spend the night here in a bivouac.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 6: Sahara Desert-Erfoud-Todra gorge-Dades Valley</p>
                <p className="day-description">Get up early to watch the sunrise from the dunes' crest. We will travel to Tineghir via Erfoud and Tinejdad after breakfast. We'll stop for lunch later before heading to the Todra gorges, where you can hike through the geological formations. Then we'll take you to your hotel in the Dades Gorges.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 7: Dades Valley-Ait Ben Haddou Kasbah-Marrakech</p>
                <p className="day-description">On this day, we return to Marrakech via the Rose Valley and Ouarzazate to see the Taourirte Kasbah and filming studios where Gladiator, Game of Thrones, and Prison Break were filmed. Finally, we will head to the red city of Marrakech.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 8: Marrakech sightseeing</p>
                <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 9: Day-trip to Essaouira</p>
                <p className="day-description">Escape Marrakech for a quick one-day trip to Essaouira. We'll stop on the way to Essaouira to see the goats on top of the iconic Argan trees. We will also have the opportunity to visit a local feminine argan cooperative and observe the argan oil production process. Later, we will arrive in Essaouira. You can go sightseeing in this small but charming city. We'll go to the ports to see the day's catch and eat a delicious sea food lunch at a local restaurant. We'll return to Marrakech in the afternoon so you can unwind at your hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 10: Marrakech-Casablanca airport</p>
                <p className="day-description">Today, your driver will take you to Casablanca's airport to catch your flight. And the tour will come to an end here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>9 nights accommodation in 3 star hotels with breakfast</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>2 dinners</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Guided tour in the city of Fes and Marrakech</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Guided camel tour in the desert</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Tickets fees to the sites</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default CExtended