import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";


const FSaharaMarrakech = () => {
  return (
    <div className="fchefchaouen-small-tour">
    <Header/>
    <section className="fchefchaouen-small-tour-heading">
        <h3>FES, SAHARA DESERT AND MARRAKECH IN 4 DAYS</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>4 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Fes</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Marrakech</p>
        </aside>
        <aside className="service-info-sections">
        <FaUsers color="#F24E1E" size="2rem"/>
        <p>8 people max</p>
        </aside>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>Fes, the Sahara Desert, and Marrakech is a four-day, three-night trip designed for those looking to quickly explore the south, with a stop in Marrakech. You will visit one of the kingdom's four imperial cities, also known as the spiritual and intellectual capital, on this day. Fes is well-known for its one-of-a-kind artisanal work. During your visit, you will see many sights in the city, including the Chouara Tannery. Then we'll head south to the Sahara desert, passing through beautiful cedar forests in cities like Ifrane and Azrou along the way. Then we'll travel to Merzouga for an authentic desert experience. After that, we'll travel to Marrakech, another imperial city, before our journey concludes.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Fes-Ifrane-Azrou-Ziz Valley-Sahara desert</p>
                <p className="day-description">We will pick you up from your hotel and travel south, passing through Ifrane, Morocco's Switzerland. We'll stop by to photograph the city's European architecture. Then we'll go to Azrou to see the wildlife of the cedar forest, particularly the monkeys. We'll then proceed to Midelt for lunch. We'll pass through Errachia, the Ziz valley and gorges, Aoufous, and Erfoud on our way to the magnificent Merzouga dunes. There we will help you check into your accommodation.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Merzouga-Hassi Labied-Khamlia-Nomad family-Fossil site-Tour of the dunes-Sahara desert</p>
                <p className="day-description">Following breakfast, you will embark on a 4x4 adventure through the Sahara desert, stopping to see volcanic rocks, an authentic nomad family, and the Khamlia village with Gnawa music entertainment. Then we'll go to Lake Sarji, which forms in the spring and attracts a wide range of wildlife. We'll then have lunch in Mezroouga or Hassi Labied. Following lunch, you'll be able to see the canal irrigation system and walk through the oasis at the dunes' foot. In the afternoon, get ready for an unforgettable 1-hour guided camel trek that will transport you to ancient times. You will ride a dromedary through the Sahara desert before arriving at your overnight camp and spending the night in a tent in the middle of the dunes.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Sahara Desert-Erfoud-Todra gorge-Dades Valley</p>
                <p className="day-description">Get up early to watch the sunrise from the dunes' crest. We will travel to Tineghir via Erfoud and Tinejdad after breakfast. We'll stop for lunch later before heading to the Todra gorges, where you can hike through the geological formations. Then we'll take you to your hotel in the Dades Gorges.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Dades Valley-Ait Ben Haddou Kasbah-Marrakech</p>
                <p className="day-description">On this day, we return to Marrakech via the Rose Valley and Ouarzazate to see the Taourirte Kasbah and filming studios where Gladiator, Game of Thrones, and Prison Break were filmed. Free time for lunch in town. Finally, we will later head to Marrakech via the majestic High Atlas mountains. Our tour ends here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>3 nights accommodation in half board (breakfast and dinner)</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Camel ride in the Sahara desert with a camel guide</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Tickets fees to the sites</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Tipss</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default FSaharaMarrakech