import React from 'react'


import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";


const FCasablanca = () => {
  return (
    <div className="fchefchaouen-small-tour">
    <Header/>
    <section className="fchefchaouen-small-tour-heading">
        <h3>DESERT TRIP FROM FES TO CASABLANCA TRIP</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>7 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Fes</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Casablanca</p>
        </aside>
        <aside className="service-info-sections">
        <FaUsers color="#F24E1E" size="2rem"/>
        <p>8 people max</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Walk inside the colorful souks.</li>
                <li>Enjoy a camel ride experience in the Sahara desert and spend the night in a Bedouin camp.</li>
                <li>Take the 1001 kasbahs road.</li>
                <li>Witness the greatness of the majestic high Atlas Moutains.</li>
                <li>Go on a day trip to the beautiful city of Essaouira.</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>A 7-day desert trip from Fes to Casablanca begins in the imperial city of Fes, also known as the spiritual and intellectual capital. During this trip, you will visit the imperial city and cities such as Ifrane, which has beautiful European architecture. Visit the Cedar Forest near Azrou to see the varied wildlife. Travel to Merzouga to see the sandy Sahara desert. You will have the full Saraha experience in Merzouga. You will experience a nomad's camel ride and spend the night in a Bedouine tent. Following that, you will travel to Marrakech via Ouarzazate and the High Atlas Mountains. You will also have the opportunity to visit Essaouira, a coastal city.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Fes-Ifrane-Azrou-Ziz Valley-Sahara desert</p>
                <p className="day-description">We will pick you up from your hotel and travel south, passing through Ifrane, Morocco's Switzerland. We'll stop by to photograph the city's European architecture. Then we'll go to Azrou to see the wildlife of the cedar forest, particularly the monkeys. We'll then proceed to Midelt for lunch. We'll pass through Errachia, the Ziz valley and gorges, Aoufous, and Erfoud on our way to the magnificent Merzouga dunes. There we will help you check into your accommodation.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Merzouga-Hassi Labied-Khamlia-Nomad family-Fossil site-Tour of the dunes-Sahara desert</p>
                <p className="day-description">Following breakfast, you will embark on a 4x4 adventure through the Sahara desert, stopping to see volcanic rocks, an authentic nomad family, and the Khamlia village with Gnawa music entertainment. Then we'll go to Lake Sarji, which forms in the spring and attracts a wide range of wildlife. We'll then have lunch in Mezroouga or Hassi Labied. Following lunch, you'll be able to see the canal irrigation system and walk through the oasis at the dunes' foot. In the afternoon, get ready for an unforgettable 1-hour guided camel trek that will transport you to ancient times. You will ride a dromedary through the Sahara desert before arriving at your overnight camp and spending the night in a tent in the middle of the dunes.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Sahara Desert-Erfoud-Todra gorge-Dades Valley</p>
                <p className="day-description">Get up early to watch the sunrise from the dunes' crest. We will travel to Tineghir via Erfoud and Tinejdad after breakfast. We'll stop for lunch later before heading to the Todra gorges, where you can hike through the geological formations. Then we'll take you to your hotel in the Dades Gorges.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Dades Valley-Ait Ben Haddou Kasbah-Marrakech</p>
                <p className="day-description">On this day, we return to Marrakech via the Rose Valley and Ouarzazate to see the Taourirte Kasbah and filming studios where Gladiator, Game of Thrones, and Prison Break were filmed. Finally, we will later head to Marrakech via the majestic High Atlas mountains.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 5: Marrakech sightseeing</p>
                <p className="day-description">In the morning, we will visit the Koutoubia mosque before heading to the colorful souks of the old medina. Furthermore, we will see more beautiful monuments such as the Saadian tombs, the Bahia palace, the Ben Youssef medrassa, the majorelle gardens, and so on. In the afternoon, the best option is to visit the jamaa El fna square as it is filled with live and entertaining shows.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 6: Day-trip to Essaouira</p>
                <p className="day-description">Escape Marrakech for a quick one-day trip to Essaouira. We'll stop on the way to Essaouira to see the goats on top of the iconic Argan trees. We will also have the opportunity to visit a local feminine argan cooperative and observe the argan oil production process. Later, we will arrive in Essaouira. You can go sightseeing in this small but charming city. We'll go to the ports to see the day's catch and eat a delicious sea food lunch at a local restaurant. We'll return to Marrakech in the afternoon so you can unwind at your hotel.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 7: Marrakech-Casablanca</p>
                <p className="day-description">Today, your driver will take you to Casablanca's airport to catch your flight. And the tour will come to an end here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>6 nights accommodation with breakfast</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Night in a luxury desert camp with your own shower and bathroom</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Camel trip in the sahara with a camel guide</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Tickets fees to the sites</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default FCasablanca
