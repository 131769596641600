import React from 'react'

import myapp_logo from '../../assets/myapp-logo.png'

import '../Footer/Footer.css'

const Footer = () => {
  return (
    <footer>
        <section className="main-footer">
            <aside className="logo">
                <img src={myapp_logo} alt="logo" width="120px"/>
            </aside>
            <aside className="footer-cat">
                    <a href="/services" className="footer-links">SERVICES</a>
                    <a href="/aboutus" className="footer-links">ABOUT US</a>
                    <a href="/privacy-policies" className="footer-links" target="_blank">PRIVACY POLICIES</a>
                    <a href="/terms-and-conditions" className="footer-links" target="_blank">TERMS & CONDITIONS</a>
                    <a href="/credits" className="footer-links" target="_blank">CREDITS</a>
            </aside>
        </section>
        <section className="copywrite">COPYWRITE 2022 WANDERCASABLANCA, ALL RIGHTS RESERVED</section>
    </footer>
    
    
  )
}

export default Footer