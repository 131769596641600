import React from 'react'
import {useRef} from 'react';

import '../Header/Header.css'

import myapp_logo from "../../assets/myapp-logo.png"

import {FaBars, FaTimes} from 'react-icons/fa'

const Header = () => {
    const navRef = useRef();

    const showNavbar = () => {
      navRef.current.classList.toggle('responsive_nav');
   }
  
    return (
      <header>
        <img src={myapp_logo} alt="logo" width="135px" />
        <nav ref={navRef} className="navbar-items">
          <a href="/" className="nav-links">Home</a>
          <a href="/Services" className="nav-links">Services</a>
          <a href="/Aboutus" className="nav-links">About us</a>
          <a href="#contact" className="nav-links">Contact</a>
          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
                 <FaTimes/>
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
              <FaBars/>
        </button>
      </header>
    )
  }

export default Header