import React from 'react'
import Header from '../../components/Header/Header'
import Contact from '../../components/Contact/Contact'
import Footer from '../../components/Footer/Footer'

import '../home/Home.css'

import myapp_logo from '../../assets/myapp-logo.png'
import Marrakech from '../../assets/Marrakech.jpg'
import Fez from '../../assets/fez.jpg'
import Tangier from '../../assets/Tangier.jpg'
import casablanca from '../../assets/casablanca.jpg'

import {FaTruckMonster,FaMapMarkerAlt} from "react-icons/fa";

const Home = () => {
  return (
    <div className="container"> 
      <Header/>
      <section className="intro" id="home">
        <h1>WANDERCASABLANCA</h1>
        <h3>Experience the Moroccan pleasures with our agency.</h3>
      </section>
      <section className="welcome-card">
        <div className="welcome-logo">
          <img src={myapp_logo} alt="logo" width="350px" />
        </div>
        <div className="welcome-message">
          <p>Greetings and welcome to <span className="home-highlight">Wandercasablanca</span>, a moroccan travel agency that organizes <span className="home-highlight">exciting and adventurous</span> tours in the kingdom of Morocco.</p>
          <p>Our main objective at Wandercasablanca is to guarantee our customers the best treatment in terms of <span className="home-highlight">safety, comfort, and pleasure</span> suitable for all types of groups: family, friends, etc...</p>
          <a href="/aboutus"><button className="aboutus-btn">LEARN MORE ABOUT OUR AGENCY</button></a>
        </div>
      </section>
      <article className="home-services">
        <section className="home-services-title">
          <FaTruckMonster color="#F24E1E" size="5rem"/>
          <h3>Discover our popular tours</h3>
        </section>  
        <section className="home-services-section-one">
          <aside className="home-services-cards">
            <img src={Marrakech} alt="Marrakech" width="300px" height="300px"/>
            <div className="home-services-card-title">
              <FaMapMarkerAlt color="#F24E1E" size="2rem"/>
              <small>Marrakech</small>
            </div>
            <a href="/marrakech-tours">
              <button className="btn-services-details">View details</button>
            </a>
          </aside>
          <aside className="home-services-cards">
            <img src={casablanca} alt="casablanca" width="300px" height="300px"/>
            <div className="home-services-card-title">
              <FaMapMarkerAlt color="#F24E1E" size="2rem"/>
              <small>Casablanca</small>
            </div>
            <a href="/casablanca-tours">
              <button className="btn-services-details">View details</button>
            </a>
          </aside>
          <aside className="home-services-cards">
            <img src={Fez} alt="Fez" width="300px" height="300px"/>
            <div className="home-services-card-title">
              <FaMapMarkerAlt color="#F24E1E" size="2rem"/>
              <small>Fes</small>
            </div>
            <a href="/fes-tours">
              <button className="btn-services-details">View details</button>
            </a>
          </aside>
          <aside className="home-services-cards">
            <img src={Tangier} alt="Tangier" width="300px" height="300px"/>
            <div className="home-services-card-title">
              <FaMapMarkerAlt color="#F24E1E" size="2rem"/>
              <small>Tangier</small>
            </div>
            <a href="/tangier-tours">
              <button className="btn-services-details">View details</button>
            </a>
          </aside>
        </section>
        <a href="/services">
            <button className="load-services-btn">CLICK HERE TO VIEW ALL SERVICES</button>
        </a>
      </article>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default Home