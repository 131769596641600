import React from 'react'

import '../../Service/Heading/Heading.css'

const Heading = () => {
  return (
    <section className="services-intro">
        <h3>Discover our different offers and services</h3>
        <h5>27 tours are now available</h5>
    </section>
  )
}

export default Heading