import React from 'react'

import { useState } from 'react';
import Slider from 'react-slick';

import '../Carousel/Carousel.css'

import img1 from '../../assets/collection/img1.jpeg'
import img2 from '../../assets/collection/img2.jpeg'
import img3 from '../../assets/collection/img3.jpeg'
import img4 from '../../assets/collection/img4.jpeg'
import img5 from '../../assets/collection/img5.jpeg'
import img6 from '../../assets/collection/img6.jpeg'
import img7 from '../../assets/collection/img7.jpeg'
import img8 from '../../assets/collection/img8.jpeg'
import img9 from '../../assets/collection/img9.jpeg'
import img10 from '../../assets/collection/img10.jpeg'
import img11 from '../../assets/collection/img11.jpeg'
import img12 from '../../assets/collection/img12.jpeg'
import img13 from '../../assets/collection/img13.jpeg'

import {FaArrowRight, FaArrowLeft} from "react-icons/fa";

const images = [img1, img2, img3, img4, img5, img6, img7, img8, img9, img10, img11, img12, img13]

const Carousel = () => {
    const NextArrow = ({onClick}) => {
        return(
            <div className="arrow next" onClick={onClick}>
                <FaArrowRight/>
            </div>
        )
    }

    const PrevArrow = ({onClick}) => {
        return(
            <div className="arrow prev" onClick={onClick}>
                <FaArrowLeft/>
            </div>
        )
    };

    const [ImageIndex, setImageIndex] = useState(0)

    const settings = {
        infinite: true,
        lazyLoad: true,
        speed: 300,
        SlidesToShow: 3,
        centerMode: true,
        centerPadding:0,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow/>,
        beforeChange: (current, next) => setImageIndex(next)
    };

    return ( 
        <div className="testimony-row" id="testimony">
            <div className="testimony-carousel">
                <Slider {...settings}>
                    {images.map((img , idx) => (
                        <div  className={idx == ImageIndex ? "slide activeSlide" : "slide"  }>
                            <img src={img} alt={img} style={{width: '100%', height: '100%'}}/>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
     );
}

export default Carousel