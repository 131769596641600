import React from 'react'

import '../../Tangier/Tangier-north-south/TNorthSouth.css'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes} from "react-icons/fa";


const TNorthSouth = () => {
  return (
    <div className="Tanger-service">
    <Header/>
    <section className="Tanger-service-heading">
        <h3>TANGIER NORTH TO SOUTH TOUR</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>8 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Tangier</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Agadir</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Walk around the sublime city of Tangier.</li>
                <li>Explore the enchating blue city of Chefchaouen.</li>
                <li>Discover the spiritual and intelectual capital of Morocco, Fes.</li>
                <li>Experience a camel ride like a real nomad.</li>
                <li>Relax for the night in the majestic Ait ben Haddou Kasbah.</li>
                <li>Enjoy the fresh air of the ocean in Agadir.</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>Explore Morocco's kingdom from north to south on this 8-day trip. We'll start in Tangier, the northern bride, and then travel to Chefchaouen, the blue city. Then we will travel to Fes, the spiritual capital, where you will have the opportunity to explore the old medina. After that, we will travel to the Sahara desert for an unforgettable experience before returning to Agadir via Ouarzazate and the Ait Ben Haddou Kassbah.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Arrival in Tangier</p>
                <p className="day-description">Your journey begins right here. The driver will meet you at the airport and transport you to your hotel to check in. You will have time to explore the city on your own.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Tangier-Chefchaouen-Fes</p>
                <p className="day-description">This morning, immediately after breakfast, we will depart for the magical blue city of Chefchaouen. Explore its narrow alleys and capture one-of-a-kind memories and photographs. Visit a farm to purchase a local product such as goat cheese. We will then travel to Fes, one of Morocco's imperial cities, to spend the night.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Fes sightseeing</p>
                <p className="day-description">After breakfast you will meet your English-speaking guide, you will tour the city's most well-known attractions, including The Mausoleum of Moulay Isma'il, the Attarine, the Nejjarine fountain, and the Karaouine mosque. And to finish, visit the Fes Jdid and the Merenid tombs for a panoramic view of the city.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Fes-Ifrane-Azrou-Midelt-Merzouga-Sahara Desert</p>
                <p className="day-description">Travel south, passing through Ifrane, Morocco's Switzerland. We'll stop by to photograph the city's European architecture. Then we'll go to Azrou to see the wildlife of the cedar forest, particularly the monkeys. We'll then proceed to Midelt for lunch. We'll pass through Errachia, the Ziz valley and gorges, Aoufous, and Erfoud on our way to the magnificent Merzouga dunes.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 5: Sahara Desert-Erfoud-Todra Gorges-Dades Valley-Ouarzazate-Ait ben Haddou</p>
                <p className="day-description">The sunrise will be visible from the top of the dunes after a peaceful night in the desert. After breakfast, we will leave the Sahara desert on camelback. Then we visit the Rose Valley, which hosts the Rose Festival in May, and the Skoura region, which has over 70,000 palm trees. Then we travel to Ouarzazate to see the Taourirt Kasbah, which exemplifies fine Moroccan artisanal craftsmanship, before returning to Ait Ben Haddou to see the famous Kasbah, which has appeared in a number of films and television shows, including Game of Thrones. We'll stay in a hotel here for the night.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 6: Ait ben Haddou-Taroudant-Agadir</p>
                <p className="day-description">After breakfast, we will drive to Taroudant, passing by the lower edge of the Atlas Mountains. We arrive in Taroudante, a city known for its fertile soil and as the home of Morocco's finest saffron. Following that, we will travel to Agadir, a city well-known for its resorts and hotels. We will assist you in checking in.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 7: Day trip to Crocopark and Souss Moussa national park</p>
                <p className="day-description">On this day, we'll go to Crocopark. There are 320 nile crocodiles, iguanas, pythons, turtoises, and other reptiles here. Then we travel to Souss-Massa National Park, which is located 60 kilometers south of Agadir and 40 kilometers north of Tiznit. The entrance is located at the far end of Sidi-Bnazern village. This 33,800-hectare nature reserve is home to mongooses, jackals, wild boars, and gazelles, as well as gulls, falcons, cormorants, and herons.</p>
            </aside>
            <aside className="day">
                    <p className="day-title">Day 8: Departure from Agadir</p>
                    <p className="day-description">Today, your driver will take you to Agadir's airport to catch your flight. And the tour will come to an end here.</p>
                </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>3 starts hotels or riads: 7 overnights with breakfast</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Official guided tour of Fes</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>2 dinners</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Camel ride in the sahara desert and camel guide</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Fuels and tolls</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch and dinner</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Monuments fees</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default TNorthSouth