import React from 'react'

import Header from '../../../../components/Header/Header'
import Contact from '../../../../components/Contact/Contact'
import Footer from '../../../../components/Footer/Footer'

import { FaClock, FaPlaneArrival, FaPlaneDeparture, FaCalendarCheck, FaLightbulb, FaBookOpen,FaRoad, FaInfoCircle, FaCheck, FaTimes, FaUsers} from "react-icons/fa";

import '../Fes-chefchaouen/Fchefchaouen.css'

const FChefchaouen = () => {
  return (
    <div className="fchefchaouen-small-tour">
    <Header/>
    <section className="fchefchaouen-small-tour-heading">
        <h3>FES TO CHEFCHAOUEN</h3>
    </section>
    <section className="service-info">
        <aside className="service-info-sections">
        <FaClock color="#F24E1E" size="2rem"/>
        <p>4 days</p>
        </aside>
        <aside className="service-info-sections">
        <FaCalendarCheck color="#F24E1E" size="2rem"/>
        <p>Availabale all year</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneArrival color="#F24E1E" size="2rem"/>
        <p>Fes</p>
        </aside>
        <aside className="service-info-sections">
        <FaPlaneDeparture color="#F24E1E" size="2rem"/>
        <p>Fes</p>
        </aside>
    </section>
    <section className="hightlights">
        <section className="hightlight-heading">
            <FaLightbulb color="#F24E1E" size="2rem"/>
            <h6>Highlights</h6>
        </section>
        <section className="hightlight-list">
            <ul>
                <li>Explore Fes and its 9,000 narrow alleyways.</li>
                <li>Visit the photogenic blue city, Chefchaouen..</li>
                <li>Spend your journey in a traditional riad.</li>
                <li>Snap some pictures at Chouara Tannery.</li>
                <li>Purchase some ceramic made products from a local ceramic workshop.</li>
            </ul>
        </section>
    </section>
    <section className="description">
        <section className="description-heading">
            <FaBookOpen color="#F24E1E" size="2rem"/>
            <h6>Description</h6>
        </section>
        <section className="description-content">
            <p>Fes to Chefchaouen is a four-day budget trip designed for people looking for a quick tour of Fes and Chefchaouen. On this day, you will visit one of the kingdom's four imperial cities, commonly referred to as the spiritual and intellectual capital. Fes is famous for its one-of-a-kind artisanal work. During your trip, you will see many sights in the city, including the Chouara Tannery. We will also have the opportunity to visit the beautiful blue city of Chefchaouen.</p>
        </section>
    </section>
    <section className="itinary">
        <section className="itinary-heading">
            <FaRoad color="#F24E1E" size="2rem"/>
            <h6>Itinary</h6>
        </section>
        <section className="itinary-content">
            <aside className="day">
                <p className="day-title">Day 1: Arrival in Fes</p>
                <p className="day-description">When you arrive at the airport, the driver will be waiting for you. You will be transported to your hotel to check in. You will also have time to relax and visit the medina and the souks.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 2: Fes sightseeing</p>
                <p className="day-description">We leave the Dades Valley early in the morning and travel along the 1001 kasbah road, passing through vibrant berber villages. We'll then stop in Tinghir before heading to the Todra gorges for a short hike. After that, we'll have lunch in Erfoud before heading to the magnificent Mezrouga desert for camel rides. We go to the night camp after watching the sunset for dinner and live music before calling it a night in the tents.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 3: Day trip to Chefchaouen</p>
                <p className="day-description">YWe'll travel to Chefchaouen, a beautiful blue city. Explore the narrow streets of this photogenic city. Relax in the square and soak up the moorish and Spanish vibes. Visit the kasbah from the 18th century. You can also visit a farm to purchase local products such as goat cheese. We will return you to Fes in the afternoon.</p>
            </aside>
            <aside className="day">
                <p className="day-title">Day 4: Departure from Fes</p>
                <p className="day-description">Today, your driver will take you to Fes's airport to catch your flight. And the tour will come to an end here.</p>
            </aside>
        </section>

    </section>
    <section className="tour-detail">
            <section className="tour-detail-heading">
                <FaInfoCircle color="#F24E1E" size="2rem"/>
                <h6>Includes/Excludes</h6>
            </section>
            <section className="tour-heading-content">
                <ul>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Private transportation during the tour with air conditioned vehicles, including an english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>3 nights accommodation in a traditional riad</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>3 dinners and breakfast in the riad</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Professional english speaking driver</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Pick up and drop off from the airport of Fes</p>
                    </li>
                    <li>
                        <FaCheck color="#F24E1E" size="1rem" />
                        <p>Guided tour in the city of Fes</p>
                    </li>
                </ul>
                <ul>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Lunch and drinks</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Tickets fees to the sites</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Travel insurance</p>
                    </li>
                    <li>
                        <FaTimes color="#F24E1E" size="1rem" />
                        <p>Any private expenses</p>
                    </li>
                </ul>
                
            </section>
        </section>
    <Contact/>
    <Footer/>
</div>
  )
}

export default FChefchaouen