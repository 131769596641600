import React from 'react'

import Header from '../../../components/Header/Header'
import Contact from '../../../components/Contact/Contact'
import Footer from '../../../components/Footer/Footer'

import chefchaouen from '../../../assets/chefchaouen.jpg'
import Gtour from '../../../assets/gtour.jpg'
import Souk from '../../../assets/Souk.jpg'
import Badia from '../../../assets/Badia.jpg'
import Casa from '../../../assets/casa.jpg'
import ouarzazate from '../../../assets/ouarzazate.jpg'
import fez from '../../../assets/fez.jpg'

import '../Marrakech/Marrakech.css'

const Marrakech = () => {
  return (
    <div className="marrakech">
        <Header/>
        <section className="marrakech-heading">
            <h3>MARRAKECH TOURS</h3>
        </section>
        <section className="marrakech-packs">
        <section className="marrakech-first-line">
                <article className="marrakech-pack">
                    <aside className="marrakech-photo">
                        <img src={chefchaouen} alt="chefchaouen" width="290px" height="260px" />
                    </aside>
                    <aside className="marrakech-content">
                        <h6>Morocco Grand tour</h6>
                        <p>10 days from Marrakech to the imperial cities, the Sahara, and Chefchaouen.</p>
                        <a href="/marrakech-grand-tour">
                            <button className="marrakech-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>

                <article className="marrakech-pack">
                    <aside className="marrakech-photo">
                        <img src={Gtour} alt="Sahara desert" width="290px" height="260px" />
                    </aside>
                    <aside className="marrakech-content">
                        <h6>5 days desert trip </h6>
                        <p>Discover Morocco's distinct landscapes on a 5-day tour of the country.</p>
                        <a href="/marrakech-five-days-desert-trip">
                            <button className="marrakech-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
            </section>

            <section className="marrakech-first-line">
                <article className="marrakech-pack">
                    <aside className="marrakech-photo">
                        <img src={Souk} alt="Marrakech souks" width="290px" height="260px" />
                    </aside>
                    <aside className="marrakech-content">
                        <h6>Best of Morocco</h6>
                        <p>A 12 days and 11 nights trip across the country.</p>
                        <a href="/marrakech-best-of-tour">
                            <button className="marrakech-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>

                <article className="marrakech-pack">
                    <aside className="marrakech-photo">
                        <img src={Badia} alt="Marrakech Badia palace" width="290px" height="260px" />
                    </aside>
                    <aside className="marrakech-content">
                        <h6>Marrakech to the south</h6>
                        <p>Take a four-day trip to the south of Morocco, beginning in Marrakech.</p>
                        <a href="/marrakech-to-the-south-tour">
                            <button className="marrakech-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
            </section>

            <section className="marrakech-first-line">
                <article className="marrakech-pack">
                    <aside className="marrakech-photo">
                        <img src={Casa} alt="Casablanca" width="290px" height="260px" />
                    </aside>
                    <aside className="marrakech-content">
                        <h6>Morocco small tour</h6>
                        <p>Discover Marrakech and Casablanca on a 7 days trip.</p>
                        <a href="/marrakech-small-tour">
                            <button className="marrakech-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>

                <article className="marrakech-pack">
                    <aside className="marrakech-photo">
                        <img src={ouarzazate} alt="Agafay deserte" width="290px" height="260px" />
                    </aside>
                    <aside className="marrakech-content">
                        <h6>Marrakech to the sahara</h6>
                        <p>A 3 days trip to the south starting from Marrakech.</p>
                        <a href="/marrakech-sahara">
                            <button className="marrakech-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
            </section>

            <section className="marrakech-first-line">
                <article className="marrakech-pack">
                    <aside className="marrakech-photo">
                        <img src={fez} alt="Fes" width="290px" height="260px" />
                    </aside>
                    <aside className="marrakech-content">
                        <h6>Marrakech to Fes via the Sahara Desert</h6>
                        <p>A three-day, two-night journey from Marrakech to Fez through the Sahara desert.</p>
                        <a href="/marrakech-fes-desert">
                            <button className="marrakech-pack-detail-btn">VIEW MORE DETAILS ABOUT THE TOUR</button>
                        </a>
                    </aside>
                </article>
            </section>
            
        </section>

        
        <Contact/>
        <Footer/>
    </div>
  )
}

export default Marrakech